import React, { useState, useEffect } from "react"
import Loader from "../Loader/loader.js"
import { Row, Col, Card, CardBody, Modal, Spinner } from "reactstrap"
import axios from "axios"
import {
   AvForm,
   AvField,
   AvRadio,
   AvRadioGroup,
} from "availity-reactstrap-validation"
import {
   getCurrentUser,
   getPermission,
   getFilterDate,
   setFilterDate,
} from "../../helpers/Utils"
import { Link, useLocation, useHistory } from "react-router-dom"
import Paginations from "../Pagination"
import LeadList from "./leadlist"
import LeadModal from "./leadmodal"
import SweetAlert from "react-bootstrap-sweetalert"
import MultipleChoice from "../Filter/MultipleChoice"
import DateFilter from "../Filter/DateFilter"
import InputFilter from "../Filter/SearchFilter"
import LeadsFilter from "../LeadFilter"
import AssignLabels from "../Assign-Labels"
import CampaignModel from "../Campaigns"
import AttachmentModal from "../attachmentModal"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"

let selectedUserId = []

const Tablelead = () => {
   const history = useHistory()
   const [lead_filter, setlead_filter] = useState(false)
   const [attachment_model, setattachment_model] = useState(false)
   const [campaign_model, setcampaign_model] = useState(false)
   const [assignLabel, setAssignLabel] = useState(false)
   const [modal_standard, setmodal_standard] = useState(false)
   const [id_filter, setid_filter] = useState(false)
   const [name_filter, setname_filter] = useState(false)
   const [contact_filter, setcontact_filter] = useState(false)
   const [leadsource_filter, setleadsource_filter] = useState(false)
   const [tag_filter, settag_filter] = useState(false)
   const [label_filter, setlabel_filter] = useState(false)
   const [leadholder_filter, setleadholder_filter] = useState(false)
   const [status_filter, setstatus_filter] = useState(false)
   const [date_filter, setdate_filter] = useState(false)
   const [important_date_filter, setimportant_date_filter] = useState(false)
   const [message_filter, setmessage_filter] = useState(false)
   const [modal, setModal] = useState(false)
   const [modal2, setModal2] = useState(false)
   const [modal3, setModal3] = useState(false)
   const [modal4, setModal4] = useState(false)
   const [exportModal, setExportModal] = useState(false)
   const [sort, setSort] = useState(false)
   const [sort2, setSort2] = useState(false)
   const [sort3, setSort3] = useState(false)
   const [sort4, setSort4] = useState(false)
   const [sort5, setSort5] = useState(false)
   const [sort6, setSort6] = useState(false)
   const [file, setFile] = useState("")
   const [exportData, setExportData] = useState(0)
   const [allLeads, setAllLeads] = useState([])
   const [error, setError] = useState("")
   const [followupeError, setFollowupeError] = useState("")
   const [loading, setLoading] = useState(false)
   const [loader, setLoader] = useState(<Loader />)
   const [loader2, setLoader2] = useState(false)
   const [loader3, setLoader3] = useState(<Loader />)
   const [loader4, setLoader4] = useState(false)
   const [allPages, setAllPages] = useState([])
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [confirm_alert2, setconfirm_alert2] = useState(false)
   const [confirm_alert3, setconfirm_alert3] = useState(false)
   const [confirm_alert4, setconfirm_alert4] = useState(false)
   const [blur, setBlur] = useState(false)
   const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
   const [counter, setCounter] = useState("")
   const [allData, setAllData] = useState([])
   const [followUps, setFollowUps] = useState([])
   const [total, setTotal] = useState("")
   const [searchedCount, setSearchedCount] = useState("")
   const [check, setCheck] = useState(0)
   const [transferNew, setTransferNew] = useState("0")
   const [hideHistory, setHideHistory] = useState("0")
   const [convertedTime, setConvertedTime] = useState("")
   const [savedFilters, setSavedFilters] = useState([])
   const [filterToDelete, setFilterToDelete] = useState(null)

   function useQuery() {
      const { search } = useLocation()
      return React.useMemo(() => new URLSearchParams(search), [search])
   }

   let query = useQuery()
   let url1 = query.get("id")
   let url2 = query.get("type")
   let url3 = query.get("followup_status_id")
   let url4 = query.get("transfer_request")
   let url5 = query.get("lead_source_id")
   let url6 = query.get("leadholder_id")
   let url7 = query.get("assigned")
   let url8 = query.get("from")
   let url9 = query.get("to")
   let url10 = query.get("lead_type")
   let url11 = query.get("search")
   let url12 = query.get("lead_label_id")
   let url13 = query.get("dead_reason_id")
   let url14 = query.get("statistics")
   let url15 = query.get("campaign_id")
   let url16 = query.get("global_search")
   let url17 = query.get("no_updation")
   let url18 = query.get("nextFollowupDate")
   let url19 = query.get("mature_status_id")
   let url20 = query.get("mature_status_options")
   let url21 = query.get("reload")
   let url22 = query.get("assigned2")
   let url23 = query.get("assigned_from")
   let url24 = query.get("assigned_to")
   let url25 = query.get("createdBy_id")
   let url26 = query.get("isadded")

   const getAllLeads = (id, message) => {
      let url
      setError("")
      setLoader(<Loader />)
      setAllLeads([])
      if (id !== undefined) {
         url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
         )}/lead${id}&per_page=${localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
            }`
         axios
            .get(`${url}`, {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            })
            .then(function (res) {
               if (res.data.status) {
                  setLoader("")
                  setCounter(res.data.data.from)
                  setAllLeads(res.data.data.data)
                  setTotal(res.data.data.total)
                  setAllPages(res.data.data)
                  setBlur(false)
               } else {
                  setError(res.data.message)
                  setLoader("")
               }
            })
      } else {
         if (message !== "refresh") {
            if (url21 === "1") {
               if (url2 === "" || url2 === null) {
                  if (localStorage.getItem("All-Leads-LastUpdated") !== null) {
                     const storedTimestamp = localStorage.getItem(
                        "All-Leads-LastUpdated"
                     )
                     const currentTime = new Date()
                     const timeDiff =
                        (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60)
                     if (timeDiff < 60) {
                        setConvertedTime(Math.round(timeDiff))
                        setAllLeads(JSON.parse(localStorage.getItem("All-Leads-Data")))
                        setAllPages(
                           JSON.parse(localStorage.getItem("All-Leads-PageData"))
                        )
                        setCounter(
                           JSON.parse(localStorage.getItem("All-Leads-PageData")).from
                        )
                        setTotal(
                           JSON.parse(localStorage.getItem("All-Leads-PageData")).total
                        )
                        setLoader("")
                        return false
                     }
                  }
               } else if (url2 === "new") {
                  if (localStorage.getItem("All-Leads-New-LastUpdated") !== null) {
                     const storedTimestamp = localStorage.getItem(
                        "All-Leads-New-LastUpdated"
                     )
                     const currentTime = new Date()
                     const timeDiff =
                        (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60)
                     if (timeDiff < 60) {
                        setConvertedTime(Math.round(timeDiff))
                        setAllLeads(
                           JSON.parse(localStorage.getItem("All-Leads-New-Data"))
                        )
                        setAllPages(
                           JSON.parse(localStorage.getItem("All-Leads-New-PageData"))
                        )
                        setCounter(
                           JSON.parse(localStorage.getItem("All-Leads-New-PageData"))
                              .from
                        )
                        setTotal(
                           JSON.parse(localStorage.getItem("All-Leads-New-PageData"))
                              .total
                        )
                        setLoader("")
                        return false
                     }
                  }
               } else if (url2 === "dead") {
                  if (localStorage.getItem("All-Leads-Dead-LastUpdated") !== null) {
                     const storedTimestamp = localStorage.getItem(
                        "All-Leads-Dead-LastUpdated"
                     )
                     const currentTime = new Date()
                     const timeDiff =
                        (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60)
                     if (timeDiff < 60) {
                        setConvertedTime(Math.round(timeDiff))
                        setAllLeads(
                           JSON.parse(localStorage.getItem("All-Leads-Dead-Data"))
                        )
                        setAllPages(
                           JSON.parse(localStorage.getItem("All-Leads-Dead-PageData"))
                        )
                        setCounter(
                           JSON.parse(localStorage.getItem("All-Leads-Dead-PageData"))
                              .from
                        )
                        setTotal(
                           JSON.parse(localStorage.getItem("All-Leads-Dead-PageData"))
                              .total
                        )
                        setLoader("")
                        return false
                     }
                  }
               } else if (url2 === "dead_request") {
                  if (
                     localStorage.getItem("All-Leads-DeadRequest-LastUpdated") !== null
                  ) {
                     const storedTimestamp = localStorage.getItem(
                        "All-Leads-DeadRequest-LastUpdated"
                     )
                     const currentTime = new Date()
                     const timeDiff =
                        (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60)
                     if (timeDiff < 60) {
                        setConvertedTime(Math.round(timeDiff))
                        setAllLeads(
                           JSON.parse(localStorage.getItem("All-Leads-DeadRequest-Data"))
                        )
                        setAllPages(
                           JSON.parse(
                              localStorage.getItem("All-Leads-DeadRequest-PageData")
                           )
                        )
                        setCounter(
                           JSON.parse(
                              localStorage.getItem("All-Leads-DeadRequest-PageData")
                           ).from
                        )
                        setTotal(
                           JSON.parse(
                              localStorage.getItem("All-Leads-DeadRequest-PageData")
                           ).total
                        )
                        setLoader("")
                        return false
                     }
                  }
               } else if (url2 === "matured") {
                  if (
                     localStorage.getItem("All-Leads-Matured-LastUpdated") !== null
                  ) {
                     const storedTimestamp = localStorage.getItem(
                        "All-Leads-Matured-LastUpdated"
                     )
                     const currentTime = new Date()
                     const timeDiff =
                        (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60)
                     if (timeDiff < 60) {
                        setConvertedTime(Math.round(timeDiff))
                        setAllLeads(
                           JSON.parse(localStorage.getItem("All-Leads-Matured-Data"))
                        )
                        setAllPages(
                           JSON.parse(localStorage.getItem("All-Leads-Matured-PageData"))
                        )
                        setCounter(
                           JSON.parse(localStorage.getItem("All-Leads-Matured-PageData"))
                              .from
                        )
                        setTotal(
                           JSON.parse(localStorage.getItem("All-Leads-Matured-PageData"))
                              .total
                        )
                        setLoader("")
                        return false
                     }
                  }
               } else if (url2 === "followup") {
                  if (
                     localStorage.getItem("All-Leads-Followup-LastUpdated") !== null
                  ) {
                     const storedTimestamp = localStorage.getItem(
                        "All-Leads-Followup-LastUpdated"
                     )
                     const currentTime = new Date()
                     const timeDiff =
                        (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60)
                     if (timeDiff < 60) {
                        setConvertedTime(Math.round(timeDiff))
                        setAllLeads(
                           JSON.parse(localStorage.getItem("All-Leads-Followup-Data"))
                        )
                        setAllPages(
                           JSON.parse(
                              localStorage.getItem("All-Leads-Followup-PageData")
                           )
                        )
                        setCounter(
                           JSON.parse(
                              localStorage.getItem("All-Leads-Followup-PageData")
                           ).from
                        )
                        setTotal(
                           JSON.parse(
                              localStorage.getItem("All-Leads-Followup-PageData")
                           ).total
                        )
                        setLoader("")
                        return false
                     }
                  }
               }
            } else {
            }
         }
         url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
         )}/lead?&per_page=${localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
            }
            &id=${url1 !== null ? url1 : ""}&type=${url2 !== null ? url2 : ""}
            &global_search=${url16 !== null ? url16 : ""}
            &followup_type=${url10 !== null ? url10 : ""}
            &followup_status_id=${url3 !== null ? url3 : ""}
            &mature_status_id=${url19 !== null ? url19 : ""}
            &mature_status_options=${url20 !== null ? url20 : ""}
            &transfer_request=${url4 !== null ? url4 : ""}
            &campaign_id=${url15 !== null ? url15 : ""}
            &lead_source_id=${url5 !== null ? url5 : ""}
            &lead_label_id=${url12 !== null ? url12 : ""}
            &dead_reason_id=${url13 !== null ? url13 : ""}
            &leadholder_id=${url6 !== null ? url6 : ""}
            &created_by=${url25 !== null ? url25 : ""}
            &is_added=${url26 !== null ? url26 : ""}
            &search=${url11 !== null ? url11 : ""}
            &assigned=${url22 !== null
               ? url22
               : url7 !== null
                  ? url7
                  : url10 == "new"
                     ? "0"
                     : ""
            }
            ${url10 == "new"
               ? `
            &followup_from_date=${url8 ? url8 : ""}
            &followup_to_date=${url9 ? url9 : ""}`
               : `${url2 == "dead" || url2 == "dead_request"
                  ? `
               &update_start_date=${url8 !== null ? url8 : ""}
               &update_end_date=${url9 !== null ? url9 : ""}`
                  : `${url2 == "matured"
                     ? `
                  &mature_start_date=${url8 !== null ? url8 : ""}
                  &mature_end_date=${url9 !== null ? url9 : ""}`
                     : ""
                  }`
               }`
            }
            &assigned_from_date=${url23 !== null ? url23 : ""}
            &assigned_to_date=${url24 !== null ? url24 : ""}
            ${url17 == "1"
               ? `&no_updation_since=${url8 ? url8 : ""}
              `
               : `${url22 !== null
                  ? `&from_date=${url8 ? url8 : ""}&to_date=${url9 ? url9 : ""
                  }
              `
                  : ""
               }`
            }
            &nextFollowupDate=${url18 !== null ? url18 : ""}
            &page=${localStorage.getItem("current_page")}`
         axios
            .get(`${url}`, {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            })
            .then(function (res) {
               if (res.data.status) {
                  setLoader("")
                  setCounter(res.data.data.from)
                  setAllLeads(res.data.data.data)
                  setTotal(res.data.data.total)
                  setAllPages(res.data.data)
                  setBlur(false)
                  if (url21 === "1") {
                     if (url2 === "" || url2 === null) {
                        localStorage.setItem(
                           "All-Leads-LastUpdated",
                           new Date().getTime()
                        )
                        localStorage.setItem(
                           "All-Leads-Data",
                           JSON.stringify(res.data.data.data)
                        )
                        localStorage.setItem(
                           "All-Leads-PageData",
                           JSON.stringify(res.data.data)
                        )
                     } else if (url2 === "new") {
                        localStorage.setItem(
                           "All-Leads-New-LastUpdated",
                           new Date().getTime()
                        )
                        localStorage.setItem(
                           "All-Leads-New-Data",
                           JSON.stringify(res.data.data.data)
                        )
                        localStorage.setItem(
                           "All-Leads-New-PageData",
                           JSON.stringify(res.data.data)
                        )
                     } else if (url2 === "dead") {
                        localStorage.setItem(
                           "All-Leads-Dead-LastUpdated",
                           new Date().getTime()
                        )
                        localStorage.setItem(
                           "All-Leads-Dead-Data",
                           JSON.stringify(res.data.data.data)
                        )
                        localStorage.setItem(
                           "All-Leads-Dead-PageData",
                           JSON.stringify(res.data.data)
                        )
                     } else if (url2 === "dead_request") {
                        localStorage.setItem(
                           "All-Leads-DeadRequest-LastUpdated",
                           new Date().getTime()
                        )
                        localStorage.setItem(
                           "All-Leads-DeadRequest-Data",
                           JSON.stringify(res.data.data.data)
                        )
                        localStorage.setItem(
                           "All-Leads-DeadRequest-PageData",
                           JSON.stringify(res.data.data)
                        )
                     } else if (url2 === "matured") {
                        localStorage.setItem(
                           "All-Leads-Matured-LastUpdated",
                           new Date().getTime()
                        )
                        localStorage.setItem(
                           "All-Leads-Matured-Data",
                           JSON.stringify(res.data.data.data)
                        )
                        localStorage.setItem(
                           "All-Leads-Matured-PageData",
                           JSON.stringify(res.data.data)
                        )
                     } else if (url2 === "followup") {
                        localStorage.setItem(
                           "All-Leads-Followup-LastUpdated",
                           new Date().getTime()
                        )
                        localStorage.setItem(
                           "All-Leads-Followup-Data",
                           JSON.stringify(res.data.data.data)
                        )
                        localStorage.setItem(
                           "All-Leads-Followup-PageData",
                           JSON.stringify(res.data.data)
                        )
                     }
                  }
                  setConvertedTime(0)
               } else {
                  setError(res.data.message)
                  setLoader("")
               }
            })
      }
   }

   const getSearchedLeads = (e, type) => {
      setError("")
      if (type == "id") {
         localStorage.setItem("leadID", e)
      }
      if (type == "name") {
         localStorage.setItem("leadName", e)
      }
      if (type == "contact") {
         localStorage.setItem("leadContact", e)
      }
      if (type == "leadsource") {
         localStorage.setItem("leadSource", e)
      }
      if (type == "tag") {
         localStorage.setItem("leadTag", e)
      }
      if (type == "label") {
         localStorage.setItem("leadLabel", e)
      }
      if (type == "leadholder") {
         localStorage.setItem("leadHolder", e)
      }
      if (type == "status") {
         localStorage.setItem("leadStatus", e)
      }
      if (type == "date") {
         setFilterDate(e)
      }
      if (type == "important_date") {
         localStorage.setItem(
            "leadImportant_date",
            JSON.stringify({
               from: e.from,
               to: e.to,
            })
         )
      }
      if (type == "message") {
         localStorage.setItem("leadMessage", e)
      }
      if (type == "sort") {
         localStorage.setItem("leadSort", e)
      }
      setSearchedCount("")
      setAllLeads([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead?type=${url2 !== null
               ? url2
               : url14 === "1"
                  ? localStorage.getItem("leadStatus")
                     ? JSON.parse(localStorage.getItem("leadStatus")).map(
                        item => item.value
                     )
                     : ""
                  : localStorage.getItem("leadStatus") !== null
                     ? localStorage.getItem("leadStatus")
                     : ""
            }
         &id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
         &campaign_id=${url15 !== null ? url15 : ""}
         &leadholder_id=${url14 === "1"
               ? localStorage.getItem("leadHolder")
                  ? JSON.parse(localStorage.getItem("leadHolder")).map(
                     item => item.value
                  )
                  : ""
               : localStorage.getItem("leadHolder") !== null
                  ? localStorage.getItem("leadHolder")
                  : ""
            }
         &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
         &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
         &lead_label_id=${url12 !== null
               ? url12
               : url14 === "1"
                  ? localStorage.getItem("leadLabel")
                     ? JSON.parse(localStorage.getItem("leadLabel")).map(
                        item => item.value
                     )
                     : ""
                  : localStorage.getItem("leadLabel") !== null
                     ? localStorage.getItem("leadLabel")
                     : ""
            }
         &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
         &lead_source_id=${url5 !== null
               ? url5
               : url14 === "1"
                  ? localStorage.getItem("leadSource")
                     ? JSON.parse(localStorage.getItem("leadSource")).map(
                        item => item.value
                     )
                     : ""
                  : localStorage.getItem("leadSource") !== null
                     ? localStorage.getItem("leadSource")
                     : ""
            }
         &followup_status_id=${url3 !== null
               ? url3
               : url14 === "1"
                  ? localStorage.getItem("followupStatus")
                     ? JSON.parse(localStorage.getItem("followupStatus")).map(
                        item => item.value
                     )
                     : ""
                  : localStorage.getItem("followupStatus") !== null
                     ? localStorage.getItem("followupStatus")
                     : ""
            }
         &mature_status_id=${url19 !== null
               ? url19
               : url14 === "1"
                  ? localStorage.getItem("matureStatus")
                     ? JSON.parse(localStorage.getItem("matureStatus")).map(
                        item => item.value
                     )
                     : ""
                  : localStorage.getItem("matureStatus") !== null
                     ? localStorage.getItem("matureStatus")
                     : ""
            }
         &dead_reason_id=${url13 !== null
               ? url13
               : url14 === "1"
                  ? localStorage.getItem("deadReason")
                     ? JSON.parse(localStorage.getItem("deadReason")).map(
                        item => item.value
                     )
                     : ""
                  : localStorage.getItem("deadReason") !== null
                     ? localStorage.getItem("deadReason")
                     : ""
            }
         &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
         &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
         &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
         &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
         &transfer_request=${url4 !== null
               ? url4
               : localStorage.getItem("leadTransfer") !== null
                  ? localStorage.getItem("leadTransfer")
                  : ""
            }
         &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
         &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
         &assigned_from_date=${url23 !== null
               ? url23
               : localStorage.getItem("leadAssigned_at") !== ""
                  ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
                  : ""
            }
         &assigned_to_date=${url24 !== null
               ? url24
               : localStorage.getItem("leadAssigned_at") !== ""
                  ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
                  : ""
            }
         &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }
            &search=${url11 !== null ? url11 : ""} 
            &created_by=${url25 !== null ? url25 : ""}
            &is_added=${url26 !== null ? url26 : ""}
            &assigned=${url22 !== null
               ? url22
               : url7 !== null
                  ? url7
                  : localStorage.getItem("leadAssigned") !== null
                     ? localStorage.getItem("leadAssigned")
                     : url10 == "new"
                        ? "0"
                        : ""
            }
            &followup_type=${url10 !== null ? url10 : ""}
            ${url10 == "new"
               ? `
            &followup_from_date=${url8 ? url8 : ""}
            &followup_to_date=${url9 ? url9 : ""}`
               : `${url2 == "dead" || url2 == "dead_request"
                  ? `
               &update_start_date=${url8 !== null ? url8 : ""}
               &update_end_date=${url9 !== null ? url9 : ""}`
                  : `${url2 == "matured"
                     ? `
                  &mature_start_date=${url8 !== null ? url8 : ""}
                  &mature_end_date=${url9 !== null ? url9 : ""}`
                     : ""
                  }`
               }`
            }
            ${url17 == "1"
               ? `&no_updation_since=${url8 ? url8 : ""}
              `
               : ""
            }
            &from_date=${url8
               ? url8
               : localStorage.getItem("leadCreated_at") !== ""
                  ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
                  : ""
            }&to_date=${url9
               ? url9
               : localStorage.getItem("leadCreated_at") !== ""
                  ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
                  : ""
            }
            &nextFollowupDate=${url18 !== null ? url18 : ""}
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllLeads(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setid_filter(false)
               setname_filter(false)
               setcontact_filter(false)
               setleadsource_filter(false)
               settag_filter(false)
               setlabel_filter(false)
               setdate_filter(false)
               setimportant_date_filter(false)
               setmessage_filter(false)
               setleadholder_filter(false)
               setstatus_filter(false)
               setlead_filter(false)
            } else {
               setError(res.data.message)
               setlead_filter(false)
            }
         })
   }

   const objectToQueryString = obj => {
      const queryParams = new URLSearchParams()
      for (const [key, value] of Object.entries(obj)) {
         {
            key !== "customFilter" && queryParams.append(key, value)
         }
      }
      return queryParams.toString()
   }

   const getFilteredLeads = () => {
      setError("")
      setSearchedCount("")
      setAllLeads([])
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead?type=${url2 !== null
               ? url2
               : localStorage.getItem("leadStatus") !== null
                  ? localStorage.getItem("leadStatus")
                  : ""
            }
      &id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
      &campaign_id=${url15 !== null ? url15 : ""}
      &leadholder_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
      &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
      &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
      &lead_label_id=${url12 !== null
               ? url12
               : localStorage.getItem("leadLabel") !== null
                  ? localStorage.getItem("leadLabel")
                  : ""
            }
      &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
      &lead_source_id=${url5 !== null
               ? url5
               : localStorage.getItem("leadSource") !== null
                  ? localStorage.getItem("leadSource")
                  : ""
            }
      &followup_status_id=${url3 !== null
               ? url3
               : localStorage.getItem("followupStatus") !== null
                  ? localStorage.getItem("followupStatus")
                  : ""
            }
      &mature_status_id=${url19 !== null
               ? url19
               : localStorage.getItem("matureStatus") !== null
                  ? localStorage.getItem("matureStatus")
                  : ""
            }
      &dead_reason_id=${url13 !== null
               ? url13
               : localStorage.getItem("deadReason") !== null
                  ? localStorage.getItem("deadReason")
                  : ""
            }
      &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
      &followup_to=${getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
            }
      &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
      &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
      &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
      &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
      &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
      &transfer_request=${url4 !== null
               ? url4
               : localStorage.getItem("leadTransfer") !== null
                  ? localStorage.getItem("leadTransfer")
                  : ""
            }
      &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
      &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
         &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
      &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
         &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
         &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }
         &search=${url11 !== null ? url11 : ""}
         &created_by=${url25 !== null ? url25 : ""}
         &is_added=${url26 !== null ? url26 : ""}
         &assigned=${url22 !== null
               ? url22
               : url7 !== null
                  ? url7
                  : localStorage.getItem("leadAssigned") !== null
                     ? localStorage.getItem("leadAssigned")
                     : url10 == "new"
                        ? "0"
                        : ""
            }
         &followup_type=${url10 !== null ? url10 : ""}
         ${url10 == "new"
               ? `
         &followup_from_date=${url8 ? url8 : ""}
         &followup_to_date=${url9 ? url9 : ""}`
               : `${url2 == "dead" || url2 == "dead_request"
                  ? `
            &update_start_date=${url8 !== null ? url8 : ""}
            &update_end_date=${url9 !== null ? url9 : ""}`
                  : `${url2 == "matured"
                     ? `
               &mature_start_date=${url8 !== null ? url8 : ""}
               &mature_end_date=${url9 !== null ? url9 : ""}`
                     : ""
                  }`
               }`
            }
            &assigned_from_date=${url23 !== null ? url23 : ""}
            &assigned_to_date=${url24 !== null ? url24 : ""}
         ${url17 == "1"
               ? `&no_updation_since=${url8 ? url8 : ""}
           `
               : `${url22 !== null
                  ? `&from_date=${url8
                     ? url8
                     : localStorage.getItem("leadCreated_at") !== ""
                        ? JSON.parse(localStorage.getItem("leadCreated_at"))
                           .startDate
                        : ""
                  }&to_date=${url9
                     ? url9
                     : localStorage.getItem("leadCreated_at") !== ""
                        ? JSON.parse(localStorage.getItem("leadCreated_at"))
                           .endDate
                        : ""
                  }
           `
                  : ""
               }`
            }
         &nextFollowupDate=${url18 !== null ? url18 : ""}
      &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }&page=${localStorage.getItem("current_page")}
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCounter(res.data.data.from)
               setAllLeads(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setError("")
            } else {
               setError(res.data.message)
            }
            setLoader("")
         })
   }

   const paginate = e => {
      setLoader(<Loader />)
      setAllLeads([])
      setAllPages([])
      setCounter("")
      axios
         .get(
            `${e}&type=${url2 !== null
               ? url2
               : localStorage.getItem("leadStatus") !== null
                  ? localStorage.getItem("leadStatus")
                  : ""
            }
      &id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
      &campaign_id=${url15 !== null ? url15 : ""}
      &leadholder_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
      &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
      &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
      &lead_label_id=${url12 !== null
               ? url12
               : localStorage.getItem("leadLabel") !== null
                  ? localStorage.getItem("leadLabel")
                  : ""
            }
      &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
      &lead_source_id=${url5 !== null
               ? url5
               : localStorage.getItem("leadSource") !== null
                  ? localStorage.getItem("leadSource")
                  : ""
            }
      &followup_status_id=${url3 !== null
               ? url3
               : localStorage.getItem("followupStatus") !== null
                  ? localStorage.getItem("followupStatus")
                  : ""
            }
      &mature_status_id=${url19 !== null
               ? url19
               : localStorage.getItem("matureStatus") !== null
                  ? localStorage.getItem("matureStatus")
                  : ""
            }
      &dead_reason_id=${url13 !== null
               ? url13
               : localStorage.getItem("deadReason") !== null
                  ? localStorage.getItem("deadReason")
                  : ""
            }
      &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
      &followup_to=${getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
            }
      &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
      &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
      &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
      &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
      &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
      &transfer_request=${url4 !== null
               ? url4
               : localStorage.getItem("leadTransfer") !== null
                  ? localStorage.getItem("leadTransfer")
                  : ""
            }
      &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
      &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
         &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
      &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
         &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
         &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }
         &search=${url11 !== null ? url11 : ""}
         &created_by=${url25 !== null ? url25 : ""}
         &is_added=${url26 !== null ? url26 : ""}
         &assigned=${url22 !== null
               ? url22
               : url7 !== null
                  ? url7
                  : localStorage.getItem("leadAssigned") !== null
                     ? localStorage.getItem("leadAssigned")
                     : url10 == "new"
                        ? "0"
                        : ""
            }
         &followup_type=${url10 !== null ? url10 : ""}
         ${url10 == "new"
               ? `
         &followup_from_date=${url8 ? url8 : ""}
         &followup_to_date=${url9 ? url9 : ""}`
               : `${url2 == "dead" || url2 == "dead_request"
                  ? `
            &update_start_date=${url8 !== null ? url8 : ""}
            &update_end_date=${url9 !== null ? url9 : ""}`
                  : `${url2 == "matured"
                     ? `
               &mature_start_date=${url8 !== null ? url8 : ""}
               &mature_end_date=${url9 !== null ? url9 : ""}`
                     : ""
                  }`
               }`
            }
            &assigned_from_date=${url23 !== null ? url23 : ""}
            &assigned_to_date=${url24 !== null ? url24 : ""}
         ${url17 == "1"
               ? `&no_updation_since=${url8 ? url8 : ""}
           `
               : `${url22 !== null
                  ? `&from_date=${url8
                     ? url8
                     : localStorage.getItem("leadCreated_at") !== ""
                        ? JSON.parse(localStorage.getItem("leadCreated_at"))
                           .startDate
                        : ""
                  }&to_date=${url9
                     ? url9
                     : localStorage.getItem("leadCreated_at") !== ""
                        ? JSON.parse(localStorage.getItem("leadCreated_at"))
                           .endDate
                        : ""
                  }
           `
                  : ""
               }`
            }
         &nextFollowupDate=${url18 !== null ? url18 : ""}
      `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token === null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setAllLeads(res.data.data.data)
               localStorage.setItem("current_page", res.data.data.current_page)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const leadDisplay = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/dashboard?type=${url2 !== null
               ? url2
               : localStorage.getItem("leadStatus") !== null
                  ? localStorage.getItem("leadStatus")
                  : ""
            }
      &id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
      &campaign_id=${url15 !== null ? url15 : ""}
      &leadholder_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
      &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
      &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
      &lead_label_id=${url12 !== null
               ? url12
               : localStorage.getItem("leadLabel") !== null
                  ? localStorage.getItem("leadLabel")
                  : ""
            }
      &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
      &lead_source_id=${url5 !== null
               ? url5
               : localStorage.getItem("leadSource") !== null
                  ? localStorage.getItem("leadSource")
                  : ""
            }
      &followup_status_id=${url3 !== null
               ? url3
               : localStorage.getItem("followupStatus") !== null
                  ? localStorage.getItem("followupStatus")
                  : ""
            }
      &mature_status_id=${url19 !== null
               ? url19
               : localStorage.getItem("matureStatus") !== null
                  ? localStorage.getItem("matureStatus")
                  : ""
            }
      &dead_reason_id=${url13 !== null
               ? url13
               : localStorage.getItem("deadReason") !== null
                  ? localStorage.getItem("deadReason")
                  : ""
            }
      &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
      &followup_to=${getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
            }
      &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
      &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
      &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
      &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
      &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
      &transfer_request=${url4 !== null
               ? url4
               : localStorage.getItem("leadTransfer") !== null
                  ? localStorage.getItem("leadTransfer")
                  : ""
            }
      &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
      &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
         &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
      &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
         &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
            &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }
         &search=${url11 !== null ? url11 : ""}
         &created_by=${url25 !== null ? url25 : ""}
         &is_added=${url26 !== null ? url26 : ""}
         &assigned=${url22 !== null
               ? url22
               : url7 !== null
                  ? url7
                  : localStorage.getItem("leadAssigned") !== null
                     ? localStorage.getItem("leadAssigned")
                     : url10 == "new"
                        ? "0"
                        : ""
            }
         &followup_type=${url10 !== null ? url10 : ""}
         ${url10 == "new"
               ? `
         &followup_from_date=${url8 ? url8 : ""}
         &followup_to_date=${url9 ? url9 : ""}`
               : `${url2 == "dead" || url2 == "dead_request"
                  ? `
            &update_start_date=${url8 !== null ? url8 : ""}
            &update_end_date=${url9 !== null ? url9 : ""}`
                  : `${url2 == "matured"
                     ? `
               &mature_start_date=${url8 !== null ? url8 : ""}
               &mature_end_date=${url9 !== null ? url9 : ""}`
                     : ""
                  }`
               }`
            }
            &assigned_from_date=${url23 !== null ? url23 : ""}
            &assigned_to_date=${url24 !== null ? url24 : ""}
         ${url17 == "1"
               ? `&no_updation_since=${url8 ? url8 : ""}
           `
               : `${url22 !== null
                  ? `&from_date=${url8
                     ? url8
                     : localStorage.getItem("leadCreated_at") !== ""
                        ? JSON.parse(localStorage.getItem("leadCreated_at"))
                           .startDate
                        : ""
                  }&to_date=${url9
                     ? url9
                     : localStorage.getItem("leadCreated_at") !== ""
                        ? JSON.parse(localStorage.getItem("leadCreated_at"))
                           .endDate
                        : ""
                  }
           `
                  : ""
               }`
            }
         &nextFollowupDate=${url18 !== null ? url18 : ""}
            `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            setAllData(res.data.data)
            setLoader("")
         })
         .catch(err => {
            console.log("err", err)
         })
   }

   const getLeadExport = () => {
      // setLoader2(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-export-pagination`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            setExportData(res.data.data)
            setLoader3("")
         })
         .catch(err => {
            console.log("err", err)
         })
   }

   const buttons = []
   for (let i = 1; i <= exportData?.pages; i++) {
      buttons.push(
         <div className="col-md-4 mb-3" key={i}>
            <button className="btn btn-primary" onClick={() => getExportedleads(i)}>
               {i === 1 ? i : 1 + 5000 * (i - 1)} -{" "}
               {i * 5000 > exportData?.leads_count
                  ? exportData?.leads_count
                  : i * 5000}
            </button>
         </div>
      )
   }

   const getExportedleads = pageNO => {
      setLoader2(true)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-export-pagination`,
            {
               page: pageNO,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               window.location.href = res.data.file_url
               setExportModal(false)
               setTimeout(() => {
                  dFileName(res.data.file_name)
               }, 5000)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
            setLoader2(false)
         })
         .catch(err => {
            console.log("err", err)
            setLoader2(false)
         })
   }

   const followDisplay = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-statuses?perPage=5000&type=${url2 !== null ? url2 : ""}
         &followup_type=${url10 !== null ? url10 : ""}
         &followup_status_id=${url3 !== null ? url3 : ""}
         &mature_status_id=${url19 !== null ? url19 : ""}
         &mature_status_options=${url20 !== null ? url20 : ""}
         &transfer_request=${url4 !== null ? url4 : ""}
            &campaign_id=${url15 !== null ? url15 : ""}
         &lead_source_id=${url5 !== null ? url5 : ""}
         &lead_label_id=${url12 !== null ? url12 : ""}
         &dead_reason_id=${url13 !== null ? url13 : ""}
         &leadholder_id=${url6 !== null ? url6 : ""}
         &search=${url11 !== null ? url11 : ""}
         &created_by=${url25 !== null ? url25 : ""}
         &is_added=${url26 !== null ? url26 : ""}
         &assigned=${url22 !== null
               ? url22
               : url7 !== null
                  ? url7
                  : url10 == "new"
                     ? "0"
                     : ""
            }
         &nextFollowupDate=${url18 !== null ? url18 : ""}
         ${url10 == "new"
               ? `
         &followup_from_date=${url8 ? url8 : ""}
         &followup_to_date=${url9 ? url9 : ""}`
               : `${url2 == "dead" || url2 == "dead_request"
                  ? `
            &update_start_date=${url8 !== null ? url8 : ""}
            &update_end_date=${url9 !== null ? url9 : ""}`
                  : `${url2 == "matured"
                     ? `
               &mature_start_date=${url8 !== null ? url8 : ""}
               &mature_end_date=${url9 !== null ? url9 : ""}`
                     : ""
                  }`
               }`
            }
            &assigned_from_date=${url23 !== null ? url23 : ""}
            &assigned_to_date=${url24 !== null ? url24 : ""}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setFollowUps(res.data.data)
               setLoader("")
            } else {
               setFollowupeError(res.data.message)
               setLoader("")
            }
         })
   }

   const getImportFormat = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/import-format`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            window.location.href = res.data.file_url
         })
   }

   const selectAllCheck = check => {
      if (check.checked) {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = true
               leadsSelectedIds.push(parseInt(ele[i].value))
            }
         }
      } else {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = false
               var array = leadsSelectedIds
               var index = array.indexOf(parseInt(ele[i].value))
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
      }
      if (leadsSelectedIds.length === 0) {
         setBlur(false)
      } else {
         setBlur(true)
      }
   }

   const getCheckValue = check => {
      if (check.checked) {
         setTransferNew("1")
      } else {
         setTransferNew("0")
         setHideHistory("0")
      }
   }

   const getCheckValue2 = check => {
      if (check.checked) {
         setHideHistory("1")
      } else {
         setHideHistory("0")
      }
   }

   const options = []

   if (JSON.parse(localStorage.getItem("All-User"))) {
      options.push({ value: "0", label: "Company pool" })
      JSON.parse(localStorage.getItem("All-User")).map(item => {
         options.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const getSelectedId = e => {
      selectedUserId = []
      e?.map(item => selectedUserId.push(item.value))
   }

   const transferLeads = (e, value) => {
      setLoading(true)
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-transfer`,
            {
               lead_ids: leadsSelectedIds,
               user_id: selectedUserId,
               transfer_as_new: transferNew,
               hide_lead_history: hideHistory,
               type: value.type,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               getFilteredLeads()
               setLeadsSelectedIds([])
               setTransferNew("0")
               setHideHistory("0")
               setLoading(false)
               setModal2(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               setTransferNew("0")
               setHideHistory("0")
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const transferRequest = (e, value) => {
      setLoading(true)
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-transfer-request`,
            {
               lead_ids: leadsSelectedIds,
               user_id: value.user_id,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               getFilteredLeads()
               setLeadsSelectedIds([])
               setLoading(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const importLeads = (e, value) => {
      setLoading(true)
      const formData = new FormData()
      formData.append("upload", file)

      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-import`,
            formData,
            {
               headers: {
                  "content-type": "multipart/form-data",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setModal(false)
               getAllLeads()
               setLoading(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const exportLead = e => {
      let Ids = []
      let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
         "company_name"
      )}/lead-export`
      if (e == "All") {
         Ids = []
      } else if (e == "Selected") {
         Ids = leadsSelectedIds
      } else {
         Ids = []
         url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
         )}/lead-export?type=${url2 !== null
            ? url2
            : localStorage.getItem("leadStatus") !== null
               ? localStorage.getItem("leadStatus")
               : ""
            }
            &id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
            &campaign_id=${url15 !== null ? url15 : ""}
            &leadholder_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
            &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
            &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
            &lead_label_id=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : url12 !== null
                  ? url12
                  : ""
            }
            &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
            &lead_source_id=${localStorage.getItem("leadSource") !== null
               ? localStorage.getItem("leadSource")
               : ""
            }
            &followup_status_id=${localStorage.getItem("followupStatus") !== null
               ? localStorage.getItem("followupStatus")
               : ""
            }
            &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
            &dead_reason_id=${localStorage.getItem("deadReason") !== null
               ? localStorage.getItem("deadReason")
               : url13 !== null
                  ? url13
                  : ""
            }
            &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
            &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
            &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
            &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
            &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
            &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
            &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
            &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
            &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
            &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
            &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
            &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
            &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
            &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date"))
               ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }
            &search=${url11 !== null ? url11 : ""}
            &followup_type=${url10 !== null ? url10 : ""}
            &nextFollowupDate=${url18 !== null ? url18 : ""}
            &created_by=${url25 !== null ? url25 : ""}
            &is_added=${url26 !== null ? url26 : ""}
            &assigned=${url22 !== null
               ? url22
               : url7 !== null
                  ? url7
                  : localStorage.getItem("leadAssigned") !== null
                     ? localStorage.getItem("leadAssigned")
                     : url10 == "new"
                        ? "0"
                        : ""
            }
               ${url10 == "new"
               ? `
            &followup_from_date=${url8 ? url8 : ""}
            &followup_to_date=${url9 ? url9 : ""}`
               : `${url2 == "dead" || url2 == "dead_request"
                  ? `
            &update_start_date=${url8 !== null ? url8 : ""}
            &update_end_date=${url9 !== null ? url9 : ""}`
                  : `${url2 == "matured"
                     ? `
            &mature_start_date=${url8 !== null ? url8 : ""}
            &mature_end_date=${url9 !== null ? url9 : ""}`
                     : ""
                  }`
               }`
            }
            &assigned_from_date=${url23 !== null ? url23 : ""}
            &assigned_to_date=${url24 !== null ? url24 : ""}`
      }
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${url}`,
            {
               ids: Ids,
               followup_type:
                  url2 === "dead"
                     ? "dead"
                     : url2 === "dead_request"
                        ? "dead_request"
                        : url2 === "matured"
                           ? "matured"
                           : url2 === "new"
                              ? "untouched"
                              : url2 === "followup"
                                 ? "followup"
                                 : url4 === "1"
                                    ? "transfer_request"
                                    : "",
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               window.location.href = res.data.file_url
               setTimeout(() => {
                  dFileName(res.data.file_name)
               }, 5000)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const deleteLeads = () => {
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/multiple-deleted`,
            {
               ids: leadsSelectedIds,
               is_deleted: check,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCheck(0)
               setLeadsSelectedIds([])
               getSearchedLeads()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const updateFlag = () => {
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/update-flag`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getFilteredLeads()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const moveToDead = () => {
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/move-to-dead`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getSearchedLeads()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const dFileName = e => {
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/delete-file`,
            {
               file_name: e,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
            } else {
            }
         })
   }

   const getFile = e => {
      setFile(e.target.files[0])
   }

   const clearLocal = () => {
      localStorage.setItem("leadHolder", "")
      localStorage.setItem("leadLabel", "")
      localStorage.setItem("leadStatus", "")
      localStorage.setItem("leadTag", "")
      localStorage.setItem("leadName", "")
      localStorage.setItem("leadContact", "")
      localStorage.setItem("leadSource", "")
      localStorage.setItem("leadAmount", "")
      setFilterDate({
         from: "",
         to: "",
      })
      localStorage.setItem("leadMessage", "")
      localStorage.setItem("followupStatus", [])
      localStorage.setItem("matureStatus", "")
      localStorage.setItem("deadReason", "")
      localStorage.setItem("deadStatus", "")
      localStorage.setItem("latestMessage", "")
      localStorage.setItem("leadRole", "")
      localStorage.setItem("leadEmail", "")
      localStorage.setItem("leadStatus2", "")
      localStorage.setItem("leadDay", "")
      localStorage.setItem("leadStatus3", "")
      localStorage.setItem("leadmaturedby", "")
      localStorage.setItem("ledgerType", "")
      localStorage.setItem("offerType", "")
      localStorage.setItem("PrductGST", "")
      localStorage.setItem("OrderStatus", "")
      localStorage.setItem("OrderPstatus", "")
      localStorage.setItem("leadSort", "")
      localStorage.setItem("leadID", "")
      localStorage.setItem("leadTransfer", "")
      localStorage.setItem("leadFlag", "")
      localStorage.setItem("leadUpdateSince", "")
      localStorage.setItem("leadOrderSince", "")
      localStorage.setItem("leadFirmName", "")
      localStorage.setItem("leadCreated_at", "")
      localStorage.setItem("leadMatured_at", "")
      localStorage.setItem(
         "leadAssigned_at",
         JSON.stringify({
            startDate: "",
            endDate: "",
         })
      )
      localStorage.setItem("current_page", "")
      localStorage.setItem("leadAssigned", "")
      localStorage.setItem("CompanyPool", "")
      localStorage.setItem(
         "leadImportant_date",
         JSON.stringify({
            from: "",
            to: "",
         })
      )
   }

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            setModal(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   useEffect(() => {
      const unlisten = history.listen(location => {
         if (
            history.location.search !== "" &&
            history.location.search !== "?id="
         ) {
            getAllLeads(location.search)
         } else {
         }
      })
      if (url14 === "1") {
         getSearchedLeads()
      } else {
         getAllLeads()
      }
      return () => {
         unlisten()
      }
   }, [])

   const storedFilter = () => {
      setLoader4(true)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/saved-filter`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token}`,
               },
            }
         )
         .then(res => {
            if (res.data.data) {
               const filters = res.data.data
               setSavedFilters(filters)
            }
         })
         .finally(() => {
            setLoader4(false)
         })
   }

   const applyFilter = filter => {
      const parsedFilter = JSON.parse(filter)
      const newFilterLeads = objectToQueryString(parsedFilter)
      setError("")
      setSearchedCount("")
      setAllLeads([])
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead?${newFilterLeads}&custom_filters=${JSON.stringify(
               parsedFilter.customFilter
            )}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token || ""}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCounter(res.data.data.from)
               setAllLeads(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setError("")
            } else {
               setError(res.data.message)
            }
            setLoader("")
         })
         .catch(error => {
            console.error("Error fetching filtered leads:", error)
         })
   }

   const deleteFilter = id => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/saved-filter/${id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token}`,
               },
            }
         )
         .then(res => {
            if (res.data.status) {
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   return (
      <>
         {modal_standard ? (
            <LeadModal
               modal_standard={modal_standard}
               setmodal_standard={setmodal_standard}
               getAllLeads={getAllLeads}
               local={true}
            />
         ) : (
            ""
         )}
         {lead_filter ? (
            <LeadsFilter
               lead_filter={lead_filter}
               setlead_filter={setlead_filter}
               getSearchedLeads={getSearchedLeads}
               type={"All"}
            />
         ) : (
            ""
         )}
         {attachment_model ? (
            <AttachmentModal
               attachment_model={attachment_model}
               setattachment_model={setattachment_model}
               leadsSelectedIds={leadsSelectedIds}
            // setLeadsSelectedIds={setLeadsSelectedIds}
            // getAllLeads={getSearchedLeads}
            />
         ) : (
            ""
         )}
         {campaign_model ? (
            <CampaignModel
               campaign_model={campaign_model}
               setcampaign_model={setcampaign_model}
               leadsSelectedIds={leadsSelectedIds}
               setLeadsSelectedIds={setLeadsSelectedIds}
               getAllLeads={getSearchedLeads}
            />
         ) : (
            ""
         )}
         {assignLabel ? (
            <AssignLabels
               assignLabel={assignLabel}
               setAssignLabel={setAssignLabel}
               leadsSelectedIds={leadsSelectedIds}
               setLeadsSelectedIds={setLeadsSelectedIds}
               getAllLeads={getSearchedLeads}
            />
         ) : (
            ""
         )}
         {confirm_alert ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteLeads()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert(false)}
            >
               <input
                  onChange={() => setCheck(1)}
                  type="checkbox"
                  name="is_deleted"
               />
               {"  "}
               <span className="text-danger">Delete it from server as well?</span>
               <br />
               <span className="text-normal">
                  It will be automatically deleted after 7 days.
               </span>
            </SweetAlert>
         ) : null}
         {confirm_alert2 ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert2(false)
                  setsuccess_dlg(true)
                  updateFlag()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert2(false)}
            >
               <span className="text-danger">
                  Do you want to update star on selected leads?
               </span>
            </SweetAlert>
         ) : null}
         {confirm_alert3 ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert3(false)
                  setsuccess_dlg(true)
                  moveToDead()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert3(false)}
            >
               <span className="text-danger">
                  Do you want to move selected leads to Dead?
               </span>
            </SweetAlert>
         ) : null}
         {modal3 ? (
            <Card style={{ marginTop: "25px" }}>
               <CardBody>
                  <Row>
                     <Col>
                        <Link to="/leads?type=">
                           <Card className="mini-stat bg-primary text-white">
                              <CardBody>
                                 <div className="">
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                       All Leads
                                    </h5>
                                    <h4 className="fw-medium font-size-18">
                                       {allData.all_leads}{" "}
                                    </h4>
                                 </div>
                              </CardBody>
                           </Card>
                        </Link>
                     </Col>
                     <Col>
                        <Link to="/leads?type=new">
                           <Card className="mini-stat bg-primary text-white">
                              <CardBody>
                                 <div className="">
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                       New Leads
                                    </h5>
                                    <h4 className="fw-medium font-size-18">
                                       {allData.new}{" "}
                                    </h4>
                                 </div>
                              </CardBody>
                           </Card>
                        </Link>
                     </Col>
                     <Col>
                        <Link to="/leads?type=dead">
                           <Card className="mini-stat bg-primary text-white">
                              <CardBody>
                                 <div className="">
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                       Dead
                                    </h5>
                                    <h4 className="fw-medium font-size-18">
                                       {allData.dead}{" "}
                                    </h4>
                                 </div>
                              </CardBody>
                           </Card>
                        </Link>
                     </Col>
                     <Col>
                        <Link to="/leads?type=dead_request">
                           <Card className="mini-stat bg-primary text-white">
                              <CardBody>
                                 <div className="">
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                       Dead Request
                                    </h5>
                                    <h4 className="fw-medium font-size-18">
                                       {allData.dead_request}{" "}
                                    </h4>
                                 </div>
                              </CardBody>
                           </Card>
                        </Link>
                     </Col>
                     <Col>
                        <Link to="/leads?type=matured">
                           <Card className="mini-stat bg-primary text-white">
                              <CardBody>
                                 <div className="">
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                       Matured Leads
                                    </h5>
                                    <h4 className="fw-medium font-size-18">
                                       {allData.matured}{" "}
                                    </h4>
                                 </div>
                              </CardBody>
                           </Card>
                        </Link>
                     </Col>
                     <Col>
                        <Link to="/leads?transfer_request=1">
                           <Card className="mini-stat bg-primary text-white">
                              <CardBody>
                                 <div className="">
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                       Transfer Request
                                    </h5>
                                    <h4 className="fw-medium font-size-18">
                                       {allData.transfer_request}{" "}
                                    </h4>
                                 </div>
                              </CardBody>
                           </Card>
                        </Link>
                     </Col>
                     <Col>
                        <Link to="/leads?type=followup">
                           <Card className="mini-stat bg-primary text-white">
                              <CardBody>
                                 <div className="">
                                    <h5 className="font-size-16 mt-0 text-uppercase text-white-50">
                                       FollowUps
                                    </h5>
                                    <h4 className="fw-medium font-size-18">
                                       {allData.followup}{" "}
                                    </h4>
                                 </div>
                              </CardBody>
                           </Card>
                        </Link>
                     </Col>
                  </Row>
                  <div>
                     <h2 className="card-title mb-4 border-bottom pb-2">
                        Followup Stages
                     </h2>
                  </div>
                  <div className="fs-h3">
                     {followUps &&
                        followUps.map((item, i) => (
                           <h3 className="mx-1" key={i}>
                              <Link
                                 to={`/leads?type=followup&followup_status_id=${item.id}`}
                                 className="pt-2 p-2 badge"
                                 style={{ backgroundColor: "orange" }}
                              >
                                 {item.name} - {item.count}
                              </Link>
                           </h3>
                        ))}
                  </div>
                  {followupeError ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {followupeError}
                     </span>
                  ) : (
                     ""
                  )}
                  {loader}
               </CardBody>
            </Card>
         ) : (
            ""
         )}
         <div className="page-title-box">
            {getCurrentUser()?.user_schemes.length > 0 ? (
               <div className="">
                  {getCurrentUser()?.user_schemes?.map(item => (
                     <>
                        <span className="text-white fs-5 bg-warning p-2">
                           <span style={{ fontWeight: "500" }}>{item.message}</span>. -
                           Expiring on
                           <span style={{ fontWeight: "500" }}> {item.end_date}</span>
                        </span>
                        <br />
                        <br />
                     </>
                  ))}
               </div>
            ) : (
               ""
            )}
            <Row className="align-items-center">
               <Col md={2}>
                  <h6 className="page-title">
                     {url2 === "matured"
                        ? "Matured Leads"
                        : url2 === "new"
                           ? "Untouched Leads"
                           : url2 === "dead"
                              ? "Dead Leads"
                              : url2 === "dead_request"
                                 ? "Dead Request Leads"
                                 : url2 === "followup"
                                    ? "All Followups"
                                    : "All Leads"}
                     {total !== "" ? <span> ({total})</span> : ""}
                  </h6>
               </Col>
               <Col md={10}>
                  <h6 className="page-title float-end">
                     <div className="btn-group me-2 mb-2">
                        <button type="button" className="btn btn-primary">
                           Sort by
                        </button>
                        <button
                           type="button"
                           className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                           data-bs-toggle="dropdown"
                           aria-expanded="false"
                        >
                           <i className="fa fa-caret-down"></i>
                        </button>
                        <ul className="dropdown-menu">
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("name,asc", "sort")}
                              >
                                 A to Z
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("name,desc", "sort")}
                              >
                                 Z to A
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("id,desc", "sort")}
                              >
                                 Latest Added
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() =>
                                    getSearchedLeads("updated_at,desc", "sort")
                                 }
                              >
                                 Latest Updated
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() =>
                                    getSearchedLeads("followup_counter,desc", "sort")
                                 }
                              >
                                 Most Followups
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() =>
                                    getSearchedLeads("followup_counter,asc", "sort")
                                 }
                              >
                                 Least Followups
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() =>
                                    getSearchedLeads("flag_date_time,ASC", "sort")
                                 }
                              >
                                 Sort by Star
                              </span>
                           </li>
                           {localStorage.getItem("ShowImportant") ? (
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                       getSearchedLeads("important_date,ASC", "sort")
                                    }
                                 >
                                    Sort by Important Date
                                 </span>
                              </li>
                           ) : (
                              ""
                           )}
                        </ul>
                     </div>
                     {getCurrentUser() &&
                        getCurrentUser().role_name !== "Executive" ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              getSearchedLeads(getCurrentUser().id, "leadholder")
                           }}
                        >
                           My Leads
                        </button>
                     ) : (
                        ""
                     )}
                     <div className="btn-group me-2 mb-2">
                        <button type="button" className="btn filter__button">
                           Saved Filter
                        </button>
                        <button
                           type="button"
                           className="btn filter__button dropdown-toggle dropdown-toggle-split"
                           data-bs-toggle="dropdown"
                           aria-expanded="false"
                           onClick={() => {
                              storedFilter()
                           }}
                        >
                           <i className="fa fa-caret-down"></i>
                        </button>
                        <ul className="dropdown-menu">
                           {loader4 ? (
                              <li className="dropdown-item">Loading...</li>
                           ) : savedFilters.length > 0 ? (
                              savedFilters.map((filter, index) => (
                                 <li key={index}>
                                    <div className="d-flex align-items-center justify-content-space-between">
                                       <button
                                          className="dropdown-item"
                                          onClick={() => applyFilter(filter.filter)}
                                       >
                                          {filter.name}
                                       </button>
                                       <a
                                          className="text-danger me-4"
                                          onClick={() => {
                                             setconfirm_alert4(true)
                                             setFilterToDelete(filter.id)
                                          }}
                                       >
                                          <i className="fas fa-trash-alt" />
                                       </a>
                                    </div>
                                 </li>
                              ))
                           ) : (
                              <li className="dropdown-item">
                                 No saved filters available
                              </li>
                           )}
                        </ul>
                     </div>
                     {confirm_alert4 ? (
                        <SweetAlert
                           title="Are you sure?"
                           warning
                           showCancel
                           confirmButtonText="Yes, delete it!"
                           confirmBtnBsStyle="success"
                           cancelBtnBsStyle="danger"
                           onConfirm={() => {
                              setconfirm_alert4(false)
                              deleteFilter(filterToDelete)
                           }}
                           onCancel={() => setconfirm_alert4(false)}
                        >
                           <span className="text-normal">
                              You would not be able to revert this!
                           </span>
                        </SweetAlert>
                     ) : null}
                     {modal3 ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              setModal3(false)
                           }}
                        >
                           Leads Counter
                           <i className="fa fa-arrow-up ms-2"></i>
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              setModal3(true), leadDisplay(), followDisplay()
                           }}
                        >
                           Leads Counter
                           <i className="fa fa-arrow-down ms-2"></i>
                        </button>
                     )}
                     {getPermission() &&
                        getPermission().lead_import.lead_import.view.is_checked ===
                        "yes" ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              setModal(true)
                           }}
                        >
                           Import Leads
                        </button>
                     ) : (
                        ""
                     )}
                     {getPermission() &&
                        getPermission().lead_export.lead_export.view.is_checked ===
                        "yes" ? (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary">
                              Export Leads
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       getLeadExport(), setExportModal(true)
                                    }}
                                 >
                                    All
                                 </span>
                              </li>
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => exportLead("Filtered")}
                                 >
                                    Filtered
                                 </span>
                              </li>
                              {blur ? (
                                 <li>
                                    <span
                                       className="dropdown-item"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => exportLead("Selected")}
                                    >
                                       Selected
                                    </span>
                                 </li>
                              ) : (
                                 <li>
                                    <span
                                       className="dropdown-item disabled"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => exportLead("Selected")}
                                    >
                                       Selected
                                    </span>
                                 </li>
                              )}
                           </ul>
                        </div>
                     ) : (
                        ""
                     )}
                     {blur ? (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary">
                              Action
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                              {getPermission() &&
                                 getPermission().lead.lead.delete.is_checked === "yes" ? (
                                 <li>
                                    <span
                                       className="dropdown-item"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setconfirm_alert(true)}
                                    >
                                       Batch delete
                                    </span>
                                 </li>
                              ) : (
                                 <li>
                                    <span
                                       className="dropdown-item disabled"
                                       onClick={() => setconfirm_alert(true)}
                                    >
                                       Batch delete
                                    </span>
                                 </li>
                              )}
                              {getPermission() &&
                                 getPermission().lead_transfer.lead_transfer.view
                                    .is_checked === "yes" ? (
                                 getCurrentUser().role_name == "Executive" ? (
                                    getPermission() &&
                                       getPermission().transfer_request.transfer_request.view
                                          .is_checked === "yes" ? (
                                       <li>
                                          <span
                                             className="dropdown-item"
                                             style={{ cursor: "pointer" }}
                                             onClick={() => {
                                                setModal4(true)
                                             }}
                                          >
                                             Change Leadholder
                                          </span>
                                       </li>
                                    ) : (
                                       <li>
                                          <span
                                             className="dropdown-item disabled"
                                             style={{ cursor: "pointer" }}
                                             onClick={() => {
                                                setModal4(true)
                                             }}
                                          >
                                             Change Leadholder
                                          </span>
                                       </li>
                                    )
                                 ) : (
                                    <li>
                                       <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setModal2(true)
                                          }}
                                       >
                                          Change Leadholder
                                       </span>
                                    </li>
                                 )
                              ) : (
                                 <li>
                                    <span
                                       className="dropdown-item disabled"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                          setModal2(true)
                                       }}
                                    >
                                       Change Leadholder
                                    </span>
                                 </li>
                              )}
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setconfirm_alert2(true)
                                    }}
                                 >
                                    Update Star
                                 </span>
                              </li>
                              {getCurrentUser() &&
                                 getCurrentUser().role_name !== "Executive" ? (
                                 <>
                                    <li>
                                       <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setconfirm_alert3(true)
                                          }}
                                       >
                                          Move to dead
                                       </span>
                                    </li>
                                    <li>
                                       <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setAssignLabel(true)
                                          }}
                                       >
                                          Assign Labels
                                       </span>
                                    </li>
                                 </>
                              ) : (
                                 ""
                              )}
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setcampaign_model(true)}
                                 >
                                    Send Whatsapp
                                 </span>
                              </li>
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setattachment_model(true)}
                                 >
                                    Send Attachment
                                 </span>
                              </li>
                           </ul>
                        </div>
                     ) : (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary" disabled>
                              Action
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              disabled
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu"></ul>
                        </div>
                     )}
                     <button
                        className="btn filter__button me-2 mb-2"
                        onClick={() => {
                           setlead_filter(true)
                        }}
                     >
                        <i className="fa fa-filter me-2"></i>
                        Other Filters
                     </button>
                     {getPermission() &&
                        getPermission().lead.lead.create.is_checked === "yes" ? (
                        <button
                           className="btn btn-success mb-2"
                           onClick={() => {
                              setmodal_standard(true)
                           }}
                        >
                           Add New
                        </button>
                     ) : (
                        ""
                     )}
                  </h6>
               </Col>
            </Row>
            <Row>
               <Col md={7}>
                  {searchedCount !== "" ? (
                     <h6 className="">
                        Searched Leads
                        <span> ({searchedCount})</span>
                        <button
                           className="btn btn-success ms-2"
                           style={{
                              cursor: "pointer",
                           }}
                           onClick={() => {
                              setSearchedCount(""),
                                 getAllLeads(undefined, "refresh"),
                                 clearLocal()
                           }}
                        >
                           <i className="fa me-2" aria-hidden="true">
                              &#xf021;
                           </i>
                           Reset Filters
                        </button>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
               <Col md={5}>
                  {convertedTime !== "" ? (
                     <h6 className="float-end">
                        <span
                           className="fw-bold btn btn-secondary me-2"
                           style={{ pointerEvents: "none" }}
                        >
                           Last Updated {convertedTime} mins ago
                        </span>
                        <button
                           className="btn btn-primary submit__button"
                           type="button"
                           onClick={() => getAllLeads(undefined, "refresh")}
                           title="Sync Leads"
                        >
                           <i className="fa" aria-hidden="true">
                              &#xf021;
                           </i>
                        </button>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
            </Row>
         </div>
         <Modal isOpen={exportModal} centered={true}>
            <div className="modal-header">
               <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  Click to Download
               </h5>
               <button
                  type="button"
                  className="cross__btn"
                  onClick={() => setExportModal(false)}
                  aria-hidden="true"
               >
                  &times;
               </button>
            </div>
            <div className="modal-body">
               <div className="row">
                  {loader2 ? (
                     <div className="text-center">
                        <Spinner />
                        <p>Please wait while the file is being downloaded.</p>
                     </div>
                  ) : loader3 ? (
                     loader3
                  ) : (
                     buttons
                  )}
               </div>
            </div>
         </Modal>
         <Modal isOpen={modal2} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  transferLeads(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Change Leadholder
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal2(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  {/* <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Users"
                        type="select"
                        name="user_id"
                     >
                        <option value="" hidden>
                           Select User
                        </option>
                        <option value="0">Company Pool</option>
                        {JSON.parse(localStorage.getItem("AllUsers")) &&
                           JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                              <option value={item.id} key={i}>
                                 {item.name}
                              </option>
                           ))}
                     </AvField>
                  </div> */}
                  <div className="form-group mb-3">
                     <label>Select User To Assign</label>
                     <Select
                        isMulti
                        isClearable="true"
                        name="assignees"
                        options={options}
                        onChange={e => getSelectedId(e)}
                     />
                  </div>
                  <div className="d-flex">
                     <div className="mb-3 me-2">
                        <AvField
                           label="Transfer as New"
                           type="checkbox"
                           name="transfer_as_new"
                           onClick={e => getCheckValue(e.target)}
                        />
                     </div>
                     {transferNew == "1" ? (
                        <div className="mb-3 ms-4">
                           <AvField
                              label="Hide Lead History"
                              type="checkbox"
                              name="hide_lead_history"
                              onClick={e => getCheckValue2(e.target)}
                           />
                        </div>
                     ) : (
                        ""
                     )}
                  </div>
                  <div className="mb-3">
                     <label>Select Option:</label>
                     <AvRadioGroup inline name="type">
                        <AvRadio label="Today's Priority" value="priority" />
                        <AvRadio label="Tomorrow's Followup" value="followup" />
                     </AvRadioGroup>
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => setModal2(false)}
                           >
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
         <Modal isOpen={modal4} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  transferRequest(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Change Leadholder Request
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal4(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Users"
                        type="select"
                        name="user_id"
                     >
                        <option value="" hidden>
                           Select User
                        </option>
                        <option value="0">Company Pool</option>
                        {JSON.parse(localStorage.getItem("All-User")) &&
                           JSON.parse(localStorage.getItem("All-User")).map((item, i) =>
                              getCurrentUser()?.id !== item.id ? (
                                 <option value={item.id} key={i}>
                                    {item.name}
                                 </option>
                              ) : (
                                 ""
                              )
                           )}
                     </AvField>
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={() => setModal4(false)}
                           >
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
         <Modal
            isOpen={modal}
            centered={true}
         // size="sm"
         >
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  importLeads(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Import Leads
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <label>Download Sample Format</label>
                     <a
                        className="btn bg-secondary text-dark float-end"
                        onClick={() => getImportFormat()}
                     >
                        <i className="fas fa-download me-2" />
                        Download
                     </a>
                  </div>
                  <div className="mb-3 required">
                     <label>Select (xlxs) file</label>
                     <AvField
                        type="file"
                        name="upload"
                        accept=".xls,.xlsx"
                        onChange={e => getFile(e)}
                     />
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button className="btn btn-primary" type="submit">
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
         <div className="card">
            <div className="card-body">
               <div className="lead_table table table-sm table-bordered table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr className="static-header">
                           <th>
                              <input
                                 onClick={e => selectAllCheck(e.target)}
                                 className="p-0 d-inline-block"
                                 type="checkbox"
                              />
                           </th>
                           <th>#</th>
                           {/* <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 ID
                                 {id_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setid_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadID") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setid_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setid_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 {sort5 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort6(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort2(false),
                                                setSort5(false),
                                                getSearchedLeads("id,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort6(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort2(false),
                                                setSort5(false),
                                                getSearchedLeads("id,asc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(395%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort5(true),
                                                setSort2(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort(false),
                                                setSort6(false),
                                                getSearchedLeads("id,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort6(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort2(false),
                                                setSort5(false),
                                                getSearchedLeads("id,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(395%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort6 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(false),
                                                setSort5(true),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort(false),
                                                setSort6(false),
                                                getSearchedLeads("id,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(395%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )}
                                 <div className="first-filter">
                                    <InputFilter
                                       id_filter={id_filter}
                                       setid_filter={setid_filter}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </div>
                           </th> */}
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Name
                                 {name_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setname_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadName") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setname_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setname_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 {/* {sort ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort(true),
                                                setSort2(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort2 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(false),
                                                setSort(true),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )} */}
                                 <InputFilter
                                    name_filter={name_filter}
                                    setname_filter={setname_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Contact
                                 {contact_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setcontact_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadContact") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setcontact_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setcontact_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <InputFilter
                                    contact_filter={contact_filter}
                                    setcontact_filter={setcontact_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Lead Source
                                 {leadsource_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setleadsource_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadSource") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setleadsource_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setleadsource_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    leadsource_filter={leadsource_filter}
                                    setleadsource_filter={setleadsource_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           {getCurrentUser() && getCurrentUser().tag == 1 ? (
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Tag
                                    {tag_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => settag_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {localStorage.getItem("leadTag") == "" ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => settag_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => settag_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <MultipleChoice
                                       tag_filter={tag_filter}
                                       settag_filter={settag_filter}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                           ) : (
                              ""
                           )}
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Label
                                 {label_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setlabel_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadLabel") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setlabel_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setlabel_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    label_filter={label_filter}
                                    setlabel_filter={setlabel_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           {getCurrentUser().role_name == "Executive" ? (
                              ""
                           ) : (
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Leadholder
                                    {leadholder_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setleadholder_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {localStorage.getItem("leadHolder") == "" ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setleadholder_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setleadholder_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <MultipleChoice
                                       leadholder_filter={leadholder_filter}
                                       setleadholder_filter={setleadholder_filter}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                           )}
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Status
                                 {status_filter ? (
                                    <>
                                       <span
                                          className=""
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setstatus_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadStatus") == "" ? (
                                          <span
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setstatus_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setstatus_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    status_filter={status_filter}
                                    setstatus_filter={setstatus_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           {localStorage.getItem("ShowImportant") ? (
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Important date
                                    {important_date_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setimportant_date_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {JSON.parse(
                                             localStorage.getItem("leadImportant_date")
                                          )?.from !== undefined &&
                                             JSON.parse(
                                                localStorage.getItem("leadImportant_date")
                                             )?.from !== "" ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setimportant_date_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setimportant_date_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <DateFilter
                                       important_date_filter={important_date_filter}
                                       setimportant_date_filter={setimportant_date_filter}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                           ) : null}
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Followup
                                 {date_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setdate_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {getFilterDate() && getFilterDate().from == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setdate_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setdate_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 {/* {sort3 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,asc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,asc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(792%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort3(true),
                                                setSort4(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,asc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,desc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(792%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort4 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort4(false),
                                                setSort3(true),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,desc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(792%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )} */}
                                 <DateFilter
                                    date_filter={date_filter}
                                    setdate_filter={setdate_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Last Message
                                 {message_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setmessage_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadMessage") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setmessage_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setmessage_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <InputFilter
                                    message_filter={message_filter}
                                    setmessage_filter={setmessage_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>Action</th>
                        </tr>
                        {allLeads &&
                           allLeads.map((item, obj) => (
                              <LeadList
                                 key={obj}
                                 getAllLeads={getFilteredLeads}
                                 setBlur={setBlur}
                                 setLeadsSelectedIds={setLeadsSelectedIds}
                                 leadsSelectedIds={leadsSelectedIds}
                                 leadDisplay={leadDisplay}
                                 data={item}
                                 i={obj}
                                 counter={counter}
                                 global={url16}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
         {allPages ? (
            <Paginations
               data={allPages}
               paginate={paginate}
               perPage={getSearchedLeads}
            />
         ) : (
            ""
         )}
      </>
   )
}
export default Tablelead
