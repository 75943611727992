import React, { useState, useEffect } from "react"
import {
   AvForm,
   AvField,
   AvRadio,
   AvRadioGroup,
} from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Modal } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import {
   getCurrentUser,
   getPermission,
   getFilterDate,
   setFilterDate,
} from "../../helpers/Utils"
import { Link, useLocation } from "react-router-dom"
import axios from "axios"
import Paginations from "../Pagination"
import Loader from "../Loader/loader.js"
import CustomerList from "./CustomerList"
import CustomerModal from "./CustomerModal"
import MultipleChoice from "../Filter/MultipleChoice"
import InputFilter from "../Filter/SearchFilter"
import DateFilter from "../Filter/DateFilter"
import LeadsFilter from "../LeadFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AssignLabels from "../Assign-Labels"
import CampaignModel from "../Campaigns"
import Select from "react-select"
import AttachmentModal from "../attachmentModal"

let selectedUserId = []

const CustomerTable = () => {
   const [modal_customer, setmodal_customer] = useState(false)
   const [attachment_model, setattachment_model] = useState(false)
   const [campaign_model, setcampaign_model] = useState(false)
   const [id_filter, setid_filter] = useState(false)
   const [name_filter, setname_filter] = useState(false)
   const [contact_filter, setcontact_filter] = useState(false)
   const [leadholder_filter, setleadholder_filter] = useState(false)
   const [amount_filter, setamount_filter] = useState(false)
   const [maturedby_filter, setmaturedby_filter] = useState(false)
   const [date_filter, setdate_filter] = useState(false)
   const [important_date_filter, setimportant_date_filter] = useState(false)
   const [label_filter, setlabel_filter] = useState(false)
   const [tag_filter, settag_filter] = useState(false)
   const [message_filter, setmessage_filter] = useState(false)
   const [sort, setSort] = useState(false)
   const [sort2, setSort2] = useState(false)
   const [sort3, setSort3] = useState(false)
   const [sort4, setSort4] = useState(false)
   const [sort5, setSort5] = useState(false)
   const [sort6, setSort6] = useState(false)
   const [modal2, setModal2] = useState(false)
   const [modal3, setModal3] = useState(false)
   const [modal4, setModal4] = useState(false)
   const [modal5, setModal5] = useState(false)
   const [followup, setFollowup] = useState([])
   const [loading, setLoading] = useState(false)
   const [blur, setBlur] = useState(false)
   const [error, setError] = useState("")
   const [counter, setCounter] = useState("")
   const [total, setTotal] = useState("")
   const [loader, setLoader] = useState(<Loader />)
   const [allPages, setAllPages] = useState([])
   const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
   const [searchedCount, setSearchedCount] = useState("")
   const [lead_filter, setlead_filter] = useState(false)
   const [confirm_alert2, setconfirm_alert2] = useState(false)
   const [confirm_alert3, setconfirm_alert3] = useState(false)
   const [assignLabel, setAssignLabel] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [details, setDetails] = useState("")
   const [matureStatuses, setMatureStatuses] = useState([])
   const [matureError, setMatureError] = useState("")
   const [convertedTime, setConvertedTime] = useState("")


   function useQuery() {
      const { search } = useLocation()
      return React.useMemo(() => new URLSearchParams(search), [search])
   }

   let query = useQuery()
   let url1 = query.get("id")
   let url2 = query.get("no_order_since")
   let url3 = query.get("leadholder_id")

   const getAllCustomers = (e) => {
      setFollowup([])
      setSearchedCount("")
      setLoader(<Loader />)
      if (e !== "refresh") {
         if (localStorage.getItem('All-Customers-LastUpdated') !== null) {
            const storedTimestamp = localStorage.getItem("All-Customers-LastUpdated");
            const currentTime = new Date();
            const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
            if (timeDiff < 60) {
               setConvertedTime(Math.round(timeDiff));
               setFollowup(JSON.parse(localStorage.getItem("All-Customers-Data")))
               setAllPages(JSON.parse(localStorage.getItem("All-Customers-PageData")))
               setCounter(JSON.parse(localStorage.getItem("All-Customers-PageData")).from)
               setTotal(JSON.parse(localStorage.getItem("All-Customers-PageData")).total)
               setDetails(localStorage.getItem("All-Customers-Details"))
               setLoader("")
               return false
            }
         }
      }
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/customers?per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }&id=${url1 !== null ? url1 : ""}
               &no_order_since=${url2 !== null && url2 !== "" ? url2 : localStorage.getItem("leadOrderSince") !== null
               ? localStorage.getItem("leadOrderSince")
               : ""
            }
            &noPaymentSince=${localStorage.getItem("leadPaymentSince") !== null
               ? localStorage.getItem("leadPaymentSince")
               : ""
            }
               &leadholder_id=${url3 !== null ? url3 : localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
               `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setFollowup(res.data.data.data)
               setAllPages(res.data.data)
               setTotal(res.data.data.total)
               getAllDetail("save")
               setConvertedTime(0)
               localStorage.setItem('All-Customers-LastUpdated', new Date().getTime());
               localStorage.setItem("All-Customers-Data", JSON.stringify(res.data.data.data))
               localStorage.setItem("All-Customers-PageData", JSON.stringify(res.data.data))
            } else {
               setLoader("")
               setError(res.data.message)
            }
         })
      // } else {
      //    setFollowup([])
      //    axios
      //       .get(
      //          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      //             "company_name"
      //          )}/customers?lead_holder_id=${value.lead_holder_id}&email=${value.email
      //          }&number=${value.number}&name=${value.name}&lead_source_id=${value.lead_source_id
      //          }&dead_reason_id=${value.dead_reason_id}&page=${localStorage.getItem(
      //             "current_page"
      //          )}`,
      //          {
      //             headers: {
      //                "content-type": "application/json",
      //                Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
      //                   }`,
      //             },
      //          }
      //       )
      //       .then(function (res) {
      //          if (res.data.status) {
      //             setLoader("")
      //             setCounter(res.data.data.from)
      //             setFollowup(res.data.data.data)
      //             setAllPages(res.data.data)
      //             setTotal(res.data.data.total)
      //             getAllDetail()
      //          } else {
      //             setLoader("")
      //             setError(res.data.message)
      //          }
      //       })
      // }
   }

   const followDisplay = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/mature-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setMatureStatuses(res.data.data.data)
               setLoader("")
            } else {
               setMatureError(res.data.message)
               setLoader("")
            }
         })
   }

   const paginate = e => {
      setFollowup([])
      setCounter("")
      setAllPages([])
      setLoader(<Loader />)
      axios
         .get(
            `${e}&id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
      &leadholder_id=${url3 !== null && url3 !== "" ? url3 : localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
      &matured_amount=${localStorage.getItem("leadAmount") !== null
               ? localStorage.getItem("leadAmount")
               : ""
            }
      &matured_by=${localStorage.getItem("leadmaturedby") !== null
               ? localStorage.getItem("leadmaturedby")
               : ""
            }
      &lead_label_id=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : ""
            }
         &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
      &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
      &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
      &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
      &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
      &followup_to=${getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
            }
      &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
      &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
      &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
      &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
      &no_order_since=${url2 !== null && url2 !== "" ? url2 : localStorage.getItem("leadOrderSince") !== null
               ? localStorage.getItem("leadOrderSince")
               : ""
            }
            &noPaymentSince=${localStorage.getItem("leadPaymentSince") !== null
               ? localStorage.getItem("leadPaymentSince")
               : ""
            }
      &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
         &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
      &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
            &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
      &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
         &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
      &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
            &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
            &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setFollowup(res.data.data.data)
               localStorage.setItem("current_page", res.data.data.current_page)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const getSearchedLeads = (e, type) => {
      setError("")
      if (type == "id") {
         localStorage.setItem("leadID", e)
      }
      if (type == "name") {
         localStorage.setItem("leadName", e)
      }
      if (type == "contact") {
         localStorage.setItem("leadContact", e)
      }
      if (type == "label") {
         localStorage.setItem("leadLabel", e)
      }
      if (type == "tag") {
         localStorage.setItem("leadTag", e)
      }
      if (type == "leadholder") {
         localStorage.setItem("leadHolder", e)
      }
      if (type == "amount") {
         localStorage.setItem("leadAmount", e)
      }
      if (type == "leadmaturedby") {
         localStorage.setItem("leadmaturedby", e)
      }
      if (type == "date") {
         setFilterDate(e)
      }
      if (type == "important_date") {
         localStorage.setItem(
            "leadImportant_date",
            JSON.stringify({
               from: e.from,
               to: e.to,
            })
         )
      }
      if (type == "message") {
         localStorage.setItem("leadMessage", e)
      }
      if (type == "sort") {
         localStorage.setItem("leadSort", e)
      }
      if (type == "mature_status") {
         localStorage.setItem("matureStatus", e)
      }
      setSearchedCount("")
      setFollowup([])
      getAllDetail()
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/customers?id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
         &leadholder_id=${url3 !== null && url3 !== "" ? url3 : localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
         &matured_amount=${localStorage.getItem("leadAmount") !== null
               ? localStorage.getItem("leadAmount")
               : ""
            }
         &matured_by=${localStorage.getItem("leadmaturedby") !== null
               ? localStorage.getItem("leadmaturedby")
               : ""
            }
         &lead_label_id=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : ""
            }
         &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
         &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
         &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
         &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
         &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
         &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
         &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
         &no_order_since=${url2 !== null && url2 !== "" ? url2 : localStorage.getItem("leadOrderSince") !== null
               ? localStorage.getItem("leadOrderSince")
               : ""
            }
            &noPaymentSince=${localStorage.getItem("leadPaymentSince") !== null
               ? localStorage.getItem("leadPaymentSince")
               : ""
            }
         &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
            &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
         &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
         &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
         &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
            &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
         &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
            &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
            &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setFollowup(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setid_filter(false)
               setname_filter(false)
               settag_filter(false)
               setcontact_filter(false)
               setleadholder_filter(false)
               setamount_filter(false)
               setmaturedby_filter(false)
               setdate_filter(false)
               setimportant_date_filter(false)
               setlabel_filter(false)
               setmessage_filter(false)
               setlead_filter(false)
            } else {
               setError(res.data.message)
               setlead_filter(false)
            }
         })
   }

   const getFilteredLeads = () => {
      setError("")
      setSearchedCount("")
      setFollowup([])
      getAllDetail()
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/customers?id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
         &leadholder_id=${url3 !== null && url3 !== "" ? url3 : localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
         &matured_amount=${localStorage.getItem("leadAmount") !== null
               ? localStorage.getItem("leadAmount")
               : ""
            }
         &matured_by=${localStorage.getItem("leadmaturedby") !== null
               ? localStorage.getItem("leadmaturedby")
               : ""
            }
            &lead_label_id=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : ""
            }
         &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
            &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
         &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
         &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
         &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
         &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
         &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
            &no_order_since=${url2 !== null && url3 !== "" ? url2 : localStorage.getItem("leadOrderSince") !== null
               ? localStorage.getItem("leadOrderSince")
               : ""
            }
         &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
            &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
         &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
         &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
         &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
            &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
         &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
            &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
            &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }&page=${localStorage.getItem(
               "current_page"
            )}
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCounter(res.data.data.from)
               setFollowup(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
            } else {
               setError(res.data.message)
            }
            setLoader("")
         })
   }

   const getAllDetail = (e) => {
      setDetails("")
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/customer-counter?id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
      &leadholder_id=${url3 !== null && url3 !== "" ? url3 : localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
      &matured_amount=${localStorage.getItem("leadAmount") !== null
               ? localStorage.getItem("leadAmount")
               : ""
            }
      &matured_by=${localStorage.getItem("leadmaturedby") !== null
               ? localStorage.getItem("leadmaturedby")
               : ""
            }
      &lead_label_id=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : ""
            }
         &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
      &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
      &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
      &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
      &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
      &followup_to=${getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
            }
      &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
      &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
      &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
      &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
            &no_order_since=${url2 !== null && url2 !== "" ? url2 : localStorage.getItem("leadOrderSince") !== null
               ? localStorage.getItem("leadOrderSince")
               : ""
            }
            &noPaymentSince=${localStorage.getItem("leadPaymentSince") !== null
               ? localStorage.getItem("leadPaymentSince")
               : ""
            }
      &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
         &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
      &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
            &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
      &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
         &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
      &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
            &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
            &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }
      &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            setDetails(res.data.data)
            if (e === "save") {
               localStorage.setItem("All-Customers-Details", res.data.data)
            }
         })
   }

   const selectAllCheck = check => {
      if (check.checked) {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = true
               leadsSelectedIds.push(parseInt(ele[i].value))
            }
         }
      } else {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = false
               var array = leadsSelectedIds
               var index = array.indexOf(parseInt(ele[i].value))
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
      }
      if (leadsSelectedIds.length === 0) {
         setBlur(false)
      } else {
         setBlur(true)
      }
   }

   const exportLead = e => {
      let Ids = []
      let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
         "company_name"
      )}/lead-export`
      if (e == "All") {
         Ids = []
      } else if (e == "Selected") {
         Ids = leadsSelectedIds
      } else {
         Ids = []
         url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
         )}/lead-export?id=${localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
            }
   &leadholder_id=${url3 !== null && url3 !== "" ? url3 : localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
   &matured_amount=${localStorage.getItem("leadAmount") !== null
               ? localStorage.getItem("leadAmount")
               : ""
            }
   &matured_by=${localStorage.getItem("leadmaturedby") !== null
               ? localStorage.getItem("leadmaturedby")
               : ""
            }
   &lead_label_id=${localStorage.getItem("leadLabel") !== null
               ? localStorage.getItem("leadLabel")
               : ""
            }
         &tag_id=${localStorage.getItem("leadTag") !== null
               ? localStorage.getItem("leadTag")
               : ""
            }
   &contact=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            } 
   &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
   &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
   &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
   &followup_to=${getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
            }
   &lastmessage=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
   &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
   &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
   &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
   &no_order_since=${url2 !== null && url2 !== "" ? url2 : localStorage.getItem("leadOrderSince") !== null
               ? localStorage.getItem("leadOrderSince")
               : ""
            }
            &noPaymentSince=${localStorage.getItem("leadPaymentSince") !== null
               ? localStorage.getItem("leadPaymentSince")
               : ""
            }
   &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
   &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
   &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
   &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
         &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
            &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
         &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
            &assigned_from_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.startDate
               : ""
            }
         &assigned_to_date=${localStorage.getItem("leadAssigned_at") !== ""
               ? JSON.parse(localStorage.getItem("leadAssigned_at"))?.endDate
               : ""
            }
            &important_start_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).from
               : ""
            }
         &important_end_date=${JSON.parse(localStorage.getItem("leadImportant_date")) ? JSON.parse(localStorage.getItem("leadImportant_date")).to
               : ""
            }`
      }
      axios
         .post(
            `${url}`,
            {
               ids: Ids,
               followup_type: "matured",
            },

            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               window.location.href = res.data.file_url
               setTimeout(() => {
                  dFileName(res.data.file_name)
               }, 5000)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const dFileName = e => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/delete-file`,
            {
               file_name: e,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) { })
         .catch(err => { })
   }

   const updateFlag = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/update-flag`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getFilteredLeads()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const options = []
   if (JSON.parse(localStorage.getItem("AllUsers"))) {
      options.push({ value: "0", label: "Company pool" })
      JSON.parse(localStorage.getItem("AllUsers")).map(item => {
         options.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const getSelectedId = e => {
      selectedUserId = []
      e?.map(item => selectedUserId.push(item.value))
   }

   const transferLeads = (e, value) => {
      setLoading(true)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-transfer`,
            {
               lead_ids: leadsSelectedIds,
               user_id: selectedUserId,
               transfer_as_new: "0",
               hide_lead_history: "0",
               type: value.type,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               getFilteredLeads()
               setLeadsSelectedIds([])
               setLoading(false)
               setModal2(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const transferRequest = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-transfer-request`,
            {
               lead_ids: leadsSelectedIds,
               user_id: value.user_id,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               getFilteredLeads()
               setLeadsSelectedIds([])
               setLoading(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const moveToDead = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/move-to-dead`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getFilteredLeads()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const createScheme = (e, value) => {
      setLoading(true)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/create-multiple-scheme`,
            {
               customer_ids: leadsSelectedIds,
               message: value.message,
               start_date: value.start_date,
               end_date: value.end_date,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               getFilteredLeads()
               setLeadsSelectedIds([])
               setLoading(false)
               setModal3(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const clearLocal = () => {
      localStorage.setItem("leadHolder", "")
      localStorage.setItem("leadLabel", "")
      localStorage.setItem("leadStatus", "")
      localStorage.setItem("leadTag", "")
      localStorage.setItem("leadName", "")
      localStorage.setItem("leadContact", "")
      localStorage.setItem("leadSource", "")
      localStorage.setItem("leadAmount", "")
      setFilterDate({
         from: "",
         to: "",
      })
      localStorage.setItem("leadMessage", "")
      localStorage.setItem("followupStatus", "")
      localStorage.setItem("matureStatus", "")
      localStorage.setItem("deadReason", "")
      localStorage.setItem("deadStatus", "")
      localStorage.setItem("latestMessage", "")
      localStorage.setItem("leadRole", "")
      localStorage.setItem("leadEmail", "")
      localStorage.setItem("leadStatus2", "")
      localStorage.setItem("leadDay", "")
      localStorage.setItem("leadStatus3", "")
      localStorage.setItem("leadmaturedby", "")
      localStorage.setItem("ledgerType", "")
      localStorage.setItem("offerType", "")
      localStorage.setItem("PrductGST", "")
      localStorage.setItem("OrderStatus", "")
      localStorage.setItem("OrderPstatus", "")
      localStorage.setItem("leadSort", "")
      localStorage.setItem("leadID", "")
      localStorage.setItem("leadTransfer", "")
      localStorage.setItem("leadFlag", "")
      localStorage.setItem("leadUpdateSince", "")
      localStorage.setItem("leadOrderSince", "")
      localStorage.setItem("leadPaymentSince", "")
      localStorage.setItem("leadFirmName", "")
      localStorage.setItem("leadCreated_at", "")
      localStorage.setItem("leadMatured_at", "")
      localStorage.setItem(
         "leadAssigned_at",
         JSON.stringify({
            startDate: "",
            endDate: "",
         })
      )
      localStorage.setItem("current_page", "")
      localStorage.setItem("leadAssigned", "")
      localStorage.setItem("CompanyPool", "")
      localStorage.setItem("leadImportant_date", JSON.stringify({
         from: "",
         to: "",
      }))
   }

   useEffect(() => {
      getAllCustomers()
   }, [])

   return (
      <>
         {modal_customer ? (
            <CustomerModal
               modal_customer={modal_customer}
               setmodal_customer={setmodal_customer}
               getAllCustomers={getAllCustomers}
               local={true}
            />
         ) : (
            ""
         )}
         {attachment_model ? (
            <AttachmentModal
               attachment_model={attachment_model}
               setattachment_model={setattachment_model}
               leadsSelectedIds={leadsSelectedIds}
            // setLeadsSelectedIds={setLeadsSelectedIds}
            // getAllLeads={getSearchedLeads}
            />
         ) : (
            ""
         )}
         {campaign_model ? (
            <CampaignModel
               campaign_model={campaign_model}
               setcampaign_model={setcampaign_model}
               leadsSelectedIds={leadsSelectedIds}
               setLeadsSelectedIds={setLeadsSelectedIds}
               getAllLeads={getFilteredLeads}
            />
         ) : (
            ""
         )}
         {lead_filter ? (
            <LeadsFilter
               lead_filter={lead_filter}
               setlead_filter={setlead_filter}
               getSearchedLeads={getSearchedLeads}
               type={"mature"}
            />
         ) : (
            ""
         )}
         {assignLabel ? (
            <AssignLabels
               assignLabel={assignLabel}
               setAssignLabel={setAssignLabel}
               leadsSelectedIds={leadsSelectedIds}
               setLeadsSelectedIds={setLeadsSelectedIds}
               getAllLeads={getFilteredLeads}
            />
         ) : (
            ""
         )}
         {confirm_alert2 ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert2(false)
                  setsuccess_dlg(true)
                  updateFlag()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert2(false)}
            >
               <span className="text-danger">
                  Do you want to update star on selected customers?
               </span>
            </SweetAlert>
         ) : null}
         {confirm_alert3 ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert3(false)
                  setsuccess_dlg(true)
                  moveToDead()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert3(false)}
            >
               <span className="text-danger">
                  Do you want to move selected leads to Dead?
               </span>
            </SweetAlert>
         ) : null}
         {modal4 ? (
            <Card style={{ marginTop: "25px" }}>
               <CardBody>
                  <div>
                     <h2 className="card-title mb-4 border-bottom pb-2">
                        Mature Stages
                     </h2>
                  </div>
                  <div className="fs-h3">
                     {matureStatuses &&
                        matureStatuses.map((item, i) => (
                           <h3 className="mx-1" key={i}>
                              <button
                                 className="pt-2 p-2 badge"
                                 onClick={() => getSearchedLeads(item.id, "mature_status")}
                                 style={{ backgroundColor: "orange", border: "none" }}
                              >
                                 {item.name} - {item.lead_count}
                              </button>
                           </h3>
                        ))}
                  </div>
                  {matureError ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {matureError}
                     </span>
                  ) : (
                     ""
                  )}
                  {loader}
               </CardBody>
            </Card>
         ) : (
            ""
         )}
         <div className="page-title-box">
            {getCurrentUser()?.user_schemes.length > 0 ?
               <div className="">
                  {getCurrentUser()?.user_schemes?.map((item) => (
                     <>
                        <span className="text-white fs-5 bg-warning p-2">
                           <span style={{ fontWeight: "500" }}>{item.message}</span>
                           . - Expiring on
                           <span style={{ fontWeight: "500" }}> {item.end_date}</span>
                        </span>
                        <br />
                        <br />
                     </>
                  ))}
               </div>
               :
               ""
            }
            <Row className="align-items-center">
               <Col md={4}>
                  <h6 className="page-title">Customers
                     {total !== "" ? <span> ({total})</span> : ""}
                  </h6>
               </Col>
               <Col md={8}>
                  <h6 className="page-title float-end">
                     <div className="btn-group me-2 mb-2">
                        <button type="button" className="btn btn-primary">
                           Sort by
                        </button>
                        <button
                           type="button"
                           className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                           data-bs-toggle="dropdown"
                           aria-expanded="false"
                        >
                           <i className="fa fa-caret-down"></i>
                        </button>
                        <ul className="dropdown-menu">
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("name,asc", "sort")}
                              >
                                 A to Z
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("name,desc", "sort")}
                              >
                                 Z to A
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("id,desc", "sort")}
                              >
                                 Latest Added
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("updated_at,desc", "sort")}
                              >
                                 Latest Updated
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads(
                                    "followup_counter,desc",
                                    "sort"
                                 )}
                              >
                                 Most Followups
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads(
                                    "followup_counter,asc",
                                    "sort"
                                 )}
                              >
                                 Least Followups
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads(
                                    "flag_date_time,ASC",
                                    "sort"
                                 )}
                              >
                                 Sort by Star
                              </span>
                           </li>
                           {localStorage.getItem("ShowImportant") ?
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => getSearchedLeads(
                                       "important_date,ASC",
                                       "sort"
                                    )}
                                 >
                                    Sort by Important Date
                                 </span>
                              </li>
                              :
                              ""
                           }
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads(
                                    "orders_count,desc",
                                    "sort"
                                 )}
                              >
                                 Last Ordered
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads(
                                    "business_value,desc",
                                    "sort"
                                 )}
                              >
                                 Business value
                              </span>
                           </li>
                        </ul>
                     </div>

                     {getCurrentUser() &&
                        getCurrentUser().role_name !== "Executive" ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              getSearchedLeads(getCurrentUser().id, "leadholder")
                           }}
                        >
                           My Leads
                        </button>
                     ) : (
                        ""
                     )}

                     {modal4 ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              setModal4(false)
                           }}
                        >
                           Counters
                           <i className="fa fa-arrow-up ms-2"></i>
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              setModal4(true), followDisplay()
                           }}
                        >
                           Counters
                           <i className="fa fa-arrow-down ms-2"></i>
                        </button>
                     )}
                     {getPermission().lead_export.lead_export.view.is_checked ===
                        "yes" ? (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary">
                              Export Leads
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => exportLead("All")}
                                 >
                                    All
                                 </span>
                              </li>
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => exportLead("Filtered")}
                                 >
                                    Filtered
                                 </span>
                              </li>
                              {blur ? (
                                 <li>
                                    <span
                                       className="dropdown-item"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => exportLead("Selected")}
                                    >
                                       Selected
                                    </span>
                                 </li>
                              ) : (
                                 <li>
                                    <span
                                       className="dropdown-item disabled"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => exportLead("Selected")}
                                    >
                                       Selected
                                    </span>
                                 </li>
                              )}
                           </ul>
                        </div>
                     ) : (
                        ""
                     )}
                     {blur ? (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary">
                              Action
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setModal3(true)
                                    }}
                                 >
                                    Create Scheme
                                 </span>
                              </li>
                              {getPermission() &&
                                 getPermission().lead_transfer.lead_transfer.view
                                    .is_checked === "yes" ? (
                                 getCurrentUser().role_name == "Executive" ?
                                    getPermission() &&
                                       getPermission().transfer_request.transfer_request.view
                                          .is_checked === "yes" ?
                                       <li>
                                          <span
                                             className="dropdown-item"
                                             style={{ cursor: "pointer" }}
                                             onClick={() => {
                                                setModal5(true)
                                             }}
                                          >
                                             Change Leadholder
                                          </span>
                                       </li>
                                       :
                                       <li>
                                          <span
                                             className="dropdown-item disabled"
                                             style={{ cursor: "pointer" }}
                                             onClick={() => {
                                                setModal5(true)
                                             }}
                                          >
                                             Change LeadHolder
                                          </span>
                                       </li>
                                    :
                                    <li>
                                       <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setModal2(true)
                                          }}
                                       >
                                          Change Leadholder
                                       </span>
                                    </li>
                              ) : (
                                 <li>
                                    <span
                                       className="dropdown-item disabled"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                          setModal2(true)
                                       }}
                                    >
                                       Change LeadHolder
                                    </span>
                                 </li>
                              )}
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setconfirm_alert2(true)
                                    }}
                                 >
                                    Update Star
                                 </span>
                              </li>
                              {getCurrentUser() &&
                                 getCurrentUser().role_name !== "Executive" ? (
                                 <>
                                    <li>
                                       <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setconfirm_alert3(true)
                                          }}
                                       >
                                          Move to dead
                                       </span>
                                    </li>
                                    <li>
                                       <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setAssignLabel(true)
                                          }}
                                       >
                                          Assign Labels
                                       </span>
                                    </li>
                                 </>
                              ) : (
                                 ""
                              )}
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setcampaign_model(true)}
                                 >
                                    Send Whatsapp
                                 </span>
                              </li>
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setattachment_model(true)}
                                 >
                                    Send Attachment
                                 </span>
                              </li>
                           </ul>
                        </div>
                     ) : (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary" disabled>
                              Action
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              disabled
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                           </ul>
                        </div>
                     )}
                     <button
                        className="btn filter__button me-2 mb-2"
                        onClick={() => {
                           setlead_filter(true)
                        }}
                     >
                        <i className="fa fa-filter me-2"></i>
                        Other Filters
                     </button>
                     <button
                        className="btn btn-success mb-2"
                        onClick={() => {
                           setmodal_customer(true)
                        }}
                     >
                        Add New
                     </button>
                  </h6>
               </Col>
            </Row>
            <Row>
               <Col md={7}>
                  {searchedCount !== "" ? (
                     <h6 className="">
                        Searched Data
                        <span> ({searchedCount})</span>
                        <button
                           className="btn btn-success ms-2"
                           style={{
                              cursor: "pointer",
                           }}
                           onClick={() => {
                              setSearchedCount(""), getAllCustomers("refresh"), clearLocal()
                           }}
                        >
                           <i className="fa me-2" aria-hidden="true">
                              &#xf021;
                           </i>
                           Reset Filters
                        </button>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
               <Col md={5}>
                  {convertedTime !== "" ? (
                     <h6 className="float-end">
                        <span
                           className="fw-bold btn btn-secondary me-2"
                           style={{ pointerEvents: "none" }}
                        >
                           Last Updated {convertedTime} mins ago
                        </span>
                        <button
                           className="btn btn-primary submit__button"
                           type="button"
                           onClick={() => getAllCustomers("refresh")}
                           title="Sync Customers"
                        >
                           <i className="fa" aria-hidden="true">
                              &#xf021;
                           </i>
                        </button>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
            </Row>
         </div>
         <Modal isOpen={modal2} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  transferLeads(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Change Leadholder
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal2(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  {/* <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Users"
                        type="select"
                        name="user_id"
                     >
                        <option value="" hidden>
                           Select User
                        </option>
                        <option value="0">Company Pool</option>
                        {JSON.parse(localStorage.getItem("AllUsers")) &&
                           JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                              <option value={item.id} key={i}>
                                 {item.name}
                              </option>
                           ))}
                     </AvField>
                  </div> */}
                  <div className="form-group mb-3">
                     <label>Select User To Assign</label>
                     <Select
                        isMulti
                        isClearable="true"
                        name="assignees"
                        options={options}
                        onChange={e => getSelectedId(e)}
                     />
                  </div>
                  <div className="mb-3">
                     <label>Select Option:</label>
                     <AvRadioGroup inline name="type">
                        <AvRadio label="Today's Priority" value="priority" />
                        <AvRadio label="Tomorrow's Followup" value="followup" />
                     </AvRadioGroup>
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => setModal2(false)}
                           >
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
         <Modal isOpen={modal3} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  createScheme(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Create Scheme
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal3(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3 required">
                     <AvField label="Message" type="textarea" name="message" />
                  </div>
                  <div className="mb-3 required">
                     <AvField label="Start Date" type="date" name="start_date" />
                  </div>
                  <div className="mb-3 required">
                     <AvField label="End Date" type="date" name="end_date" />
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => setModal2(false)}
                           >
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
         <Modal isOpen={modal5} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  transferRequest(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Change Leadholder Request
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal5(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Users"
                        type="select"
                        name="user_id"
                     >
                        <option value="" hidden>
                           Select User
                        </option>
                        <option value="0">Company Pool</option>
                        {JSON.parse(localStorage.getItem("All-User")) &&
                           JSON.parse(localStorage.getItem("All-User")).map((item, i) => (
                              getCurrentUser()?.id !== item.id ?
                                 <option value={item.id} key={i}>
                                    {item.name}
                                 </option>
                                 :
                                 ""
                           ))}
                     </AvField>
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={() => setModal5(false)}
                           >
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
         {details !== "" ? (
            <div className="fs-3 d-flex justify-content-center alert alert-warning">
               <h2>
                  <i className="fas fa-users me-2"></i>
                  {allPages?.total} |{" "}
               </h2>{" "}
               <h2 className="ms-2">{details}</h2>
            </div>
         ) : (
            ""
         )}
         {/* <div className="card">
        <div className="card-body"> */}
         <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%", background: "#fff" }}>
               <tbody>
                  <tr className="static-header">
                     <th>
                        <input
                           onClick={e => selectAllCheck(e.target)}
                           className="p-0 d-inline-block"
                           type="checkbox"
                        />
                     </th>
                     <th>#</th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Name
                           {name_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setname_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadName") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setname_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setname_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           {/* {sort ? (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer", color: "orange" }}
                        onClick={() => {
                          setSort2(true),
                            setSort(false),
                            setSort3(false),
                            setSort4(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("name,asc", "sort")
                        }}
                      >
                        <i className="fa fa-sort-up ms-2"></i>
                      </span>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort2(true),
                            setSort(false),
                            setSort3(false),
                            setSort4(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("name,asc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(615%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort(true),
                            setSort2(false),
                            setSort3(false),
                            setSort4(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("name,asc", "sort")
                        }}
                      >
                        <i className="fa fa-sort-up ms-2"></i>
                      </span>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort2(true),
                            setSort(false),
                            setSort3(false),
                            setSort4(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("name,desc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(615%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  )}
                  {sort2 ? (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer", color: "orange" }}
                        onClick={() => {
                          setSort2(false),
                            setSort(true),
                            setSort3(false),
                            setSort4(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("name,desc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(615%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  ) : (
                    ""
                  )} */}
                           <InputFilter
                              name_filter={name_filter}
                              setname_filter={setname_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Contact
                           {contact_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setcontact_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadContact") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setcontact_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setcontact_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <InputFilter
                              contact_filter={contact_filter}
                              setcontact_filter={setcontact_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Label
                           {label_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setlabel_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadLabel") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setlabel_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setlabel_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <MultipleChoice
                              label_filter={label_filter}
                              setlabel_filter={setlabel_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     {getCurrentUser() && getCurrentUser().tag == 1 ? (
                        <th>
                           <div
                              className="position-relative"
                              style={{ whiteSpace: "nowrap" }}
                           >
                              Tag
                              {tag_filter ? (
                                 <>
                                    <span
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => settag_filter(false)}
                                    >
                                       <i className="fa fa-times fa-lg ms-2"></i>
                                    </span>
                                 </>
                              ) : (
                                 <>
                                    {localStorage.getItem("leadTag") == "" ? (
                                       <span
                                          className=""
                                          style={{ cursor: "pointer" }}
                                          onClick={() => settag_filter(true)}
                                       >
                                          <i className="fa fa-filter ms-2"></i>
                                       </span>
                                    ) : (
                                       <span
                                          className=""
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => settag_filter(true)}
                                       >
                                          <i className="fa fa-filter ms-2"></i>
                                       </span>
                                    )}
                                 </>
                              )}
                              <MultipleChoice
                                 tag_filter={tag_filter}
                                 settag_filter={settag_filter}
                                 getSearchedLeads={getSearchedLeads}
                              />
                           </div>
                        </th>
                     ) : (
                        ""
                     )}
                     {getCurrentUser().role_name == "Executive" ? (
                        ""
                     ) : (
                        <th>
                           <div
                              className="position-relative"
                              style={{ whiteSpace: "nowrap" }}
                           >
                              Leadholder
                              {leadholder_filter ? (
                                 <>
                                    <span
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setleadholder_filter(false)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 </>
                              ) : (
                                 <>
                                    {localStorage.getItem("leadHolder") == "" ? (
                                       <span
                                          className=""
                                          style={{ cursor: "pointer" }}
                                          onClick={() => setleadholder_filter(true)}
                                       >
                                          <i className="fa fa-filter ms-2"></i>
                                       </span>
                                    ) : (
                                       <span
                                          className=""
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setleadholder_filter(true)}
                                       >
                                          <i className="fa fa-filter ms-2"></i>
                                       </span>
                                    )}
                                 </>
                              )}
                              <MultipleChoice
                                 leadholder_filter={leadholder_filter}
                                 setleadholder_filter={setleadholder_filter}
                                 getSearchedLeads={getSearchedLeads}
                              />
                           </div>
                        </th>
                     )}
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Matured amount
                           {amount_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setamount_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadAmount") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setamount_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setamount_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <InputFilter
                              amount_filter={amount_filter}
                              setamount_filter={setamount_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     {/* <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Matured by
                      {maturedby_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setmaturedby_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadmaturedby") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setmaturedby_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setmaturedby_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        maturedby_filter={maturedby_filter}
                        setmaturedby_filter={setmaturedby_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th> */}
                     <th>Summary</th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Last Order
                           {/* {sort5 ? (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer", color: "orange" }}
                        onClick={() => {
                          setSort6(true),
                            setSort(false),
                            setSort3(false),
                            setSort4(false),
                            setSort2(false),
                            setSort5(false),
                            getSearchedLeads("orders_count,asc", "sort")
                        }}
                      >
                        <i className="fa fa-sort-up ms-2"></i>
                      </span>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort6(true),
                            setSort(false),
                            setSort3(false),
                            setSort4(false),
                            setSort2(false),
                            setSort5(false),
                            getSearchedLeads("orders_count,asc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(671%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort5(true),
                            setSort2(false),
                            setSort3(false),
                            setSort4(false),
                            setSort(false),
                            setSort6(false),
                            getSearchedLeads("orders_count,asc", "sort")
                        }}
                      >
                        <i className="fa fa-sort-up ms-2"></i>
                      </span>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort6(true),
                            setSort(false),
                            setSort3(false),
                            setSort4(false),
                            setSort2(false),
                            setSort5(false),
                            getSearchedLeads("orders_count,desc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(671%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  )}
                  {sort6 ? (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer", color: "orange" }}
                        onClick={() => {
                          setSort2(false),
                            setSort5(true),
                            setSort3(false),
                            setSort4(false),
                            setSort(false),
                            setSort6(false),
                            getSearchedLeads("orders_count,desc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(671%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  ) : (
                    ""
                  )} */}
                        </div>
                     </th>
                     {localStorage.getItem("ShowImportant") ?
                        <th>
                           <div
                              className="position-relative"
                              style={{ whiteSpace: "nowrap" }}
                           >
                              Important date
                              {important_date_filter ? (
                                 <>
                                    <span
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setimportant_date_filter(false)}
                                    >
                                       <i className="fa fa-times fa-lg ms-2"></i>
                                    </span>
                                 </>
                              ) : (
                                 <>
                                    {JSON.parse(localStorage.getItem("leadImportant_date"))?.from !== undefined && JSON.parse(localStorage.getItem("leadImportant_date"))?.from !== "" ? (
                                       <span
                                          className=""
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setimportant_date_filter(true)}
                                       >
                                          <i className="fa fa-filter ms-2"></i>
                                       </span>
                                    ) : (
                                       <span
                                          className=""
                                          style={{ cursor: "pointer" }}
                                          onClick={() => setimportant_date_filter(true)}
                                       >
                                          <i className="fa fa-filter ms-2"></i>
                                       </span>
                                    )}
                                 </>
                              )}
                              <DateFilter
                                 important_date_filter={important_date_filter}
                                 setimportant_date_filter={setimportant_date_filter}
                                 getSearchedLeads={getSearchedLeads}
                              />
                           </div>
                        </th>
                        :
                        null
                     }
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Followup Date/Time
                           {date_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setdate_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {getFilterDate() && getFilterDate().from == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setdate_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setdate_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           {/* {sort3 ? (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer", color: "orange" }}
                        onClick={() => {
                          setSort4(true),
                            setSort3(false),
                            setSort(false),
                            setSort2(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("followup_counter,asc", "sort")
                        }}
                      >
                        <i className="fa fa-sort-up ms-2"></i>
                      </span>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort4(true),
                            setSort3(false),
                            setSort(false),
                            setSort2(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("followup_counter,asc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(1429%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort3(true),
                            setSort4(false),
                            setSort(false),
                            setSort2(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("followup_counter,asc", "sort")
                        }}
                      >
                        <i className="fa fa-sort-up ms-2"></i>
                      </span>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSort4(true),
                            setSort3(false),
                            setSort(false),
                            setSort2(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("followup_counter,desc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(1429%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  )}
                  {sort4 ? (
                    <>
                      <span
                        className="fs-5"
                        style={{ cursor: "pointer", color: "orange" }}
                        onClick={() => {
                          setSort4(false),
                            setSort3(true),
                            setSort(false),
                            setSort2(false),
                            setSort5(false),
                            setSort6(false),
                            getSearchedLeads("followup_counter,desc", "sort")
                        }}
                      >
                        <i
                          className="fa fa-sort-down"
                          style={{
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            transform: "translate(1429%, -3px)",
                          }}
                        ></i>
                      </span>
                    </>
                  ) : (
                    ""
                  )} */}
                           <DateFilter
                              date_filter={date_filter}
                              setdate_filter={setdate_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Last Message
                           {message_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setmessage_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadMessage") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setmessage_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setmessage_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <InputFilter
                              message_filter={message_filter}
                              setmessage_filter={setmessage_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>Action</th>
                  </tr>
                  {followup &&
                     followup.map((item, obj) => (
                        <CustomerList
                           key={obj}
                           data={item}
                           i={obj}
                           getAllCustomers={getFilteredLeads}
                           setBlur={setBlur}
                           setLeadsSelectedIds={setLeadsSelectedIds}
                           leadsSelectedIds={leadsSelectedIds}
                           counter={counter}
                           id={url1}
                        />
                     ))}
               </tbody>
            </table>
            {error ? (
               <span className="text-danger mt-3 d-table m-auto" role="alert">
                  {error}
               </span>
            ) : (
               ""
            )}
         </div>
         {/* </div>
      </div> */}
         {loader}
         {allPages ? (
            <Paginations
               data={allPages}
               paginate={paginate}
               perPage={getSearchedLeads}
            />
         ) : (
            ""
         )}
      </>
   )
}
export default CustomerTable
